export default {
  patient: 'Patient',
  treatment: 'Treatment',
  monitoring: 'Monitoring',
  communication: 'Communication',
  dayplan: 'Dayplan',
  processes: 'Processes',
  medication: 'Medication',
  history: 'History',
  state: 'State',
  adherence: 'Adherence',
  data: 'Data',
  laboratoryValues: 'Laboratory values',
  careTeam: 'Care team'
};
