import React, { useState } from "react";
import { Layout, Drawer, ConfigProvider } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { observer, Provider } from "mobx-react";
import { useStores } from "./use-stores";
import { useTranslation, withTranslation } from "react-i18next";
import i18next from "./i18n";
import deDe from "antd/lib/locale/de_DE";
import enUs from "antd/lib/locale/en_US";
import jwt_decode from "jwt-decode";
import keycloak from "./keycloak";
import NavBar from "./core/components/Header/Header";
import About from "./core/pages/About/About";
import Imprint from "./core/pages/Imprint/Imprint";
import Profil from "./core/pages/Profil/Profil";
import PatientManagement from "./patientmanagement/PatientManagement";
import Menuw from "./core/components/Menu/Menu";
import coreDE from "./core/locale/de/core";
import coreEN from "./core/locale/en/core";
import coreNavDE from "./core/locale/de/nav";
import coreNavEN from "./core/locale/en/nav";
import logoSrc from "./assets/logo.svg";

import "./App.scss";
import "./scss/App-mobil.scss";

const { Header } = Layout;

i18next.addResourceBundle("de", "core", coreDE);
i18next.addResourceBundle("en", "core", coreEN);
i18next.addResourceBundle("de", "coreNav", coreNavDE);
i18next.addResourceBundle("en", "coreNav", coreNavEN);

/**
 * * Returns entrypoint App element.
 * @function App
 * @module /core
 * @namespace core
 * @property {module:core} core
 * @type {React.FC<any>}
 * @returns {React.ReactElement}
 *
 */
const App = () => {
  const [minAnimation, setMinAnimation] = React.useState(true);
  const [navVisible, setNavVisible] = React.useState(false);
  const [detailInfoVisible, setDetailInfoVisible] = useState(false);
  const { t, i18n } = useTranslation();

  const showNav = (): void => {
    setNavVisible(true);
  };

  const onCloseNav = (): void => {
    setNavVisible(false);
  };

  const showDetail = (): void => {
    setDetailInfoVisible(true);
  };

  const onCloseDetail = (): void => {
    setDetailInfoVisible(false);
  };

  const stores = useStores();
  const {
    authStore,
    organisationManagementStore,
    uiStore,
    patientCareStore,
    messagingStore,
    therapyStore,
    medicationTherapyStore,
    hcpStore,
  } = stores;
  const { getAuthenticated, getUserAttributes } = authStore;
  const authenticated = getAuthenticated;
  const userAttributes = getUserAttributes;
  const isMobileIconVisible: boolean = uiStore.getCaseViewMobil;

  setTimeout(() => {
    setMinAnimation(false);
  }, 800);

  const getConfig = async () => {
    if (keycloak.token !== undefined) {
      const decodeToken: any = jwt_decode(keycloak.token);
      await organisationManagementStore.getConfigToOrganization(
        decodeToken.organizationIds[0]
      );
    }
  };

  if (minAnimation || userAttributes.isDoctor === null) {
    return (
      <Layout className="dtb-init-loader">
        <img src={logoSrc} alt="TinoDTB" />
        <h1
          data-text={
            !authenticated && userAttributes.isDoctor !== null
              ? t("core:checkingPermissionStatus")
              : t("core:forMedicalInstitutions")
          }
        >
          {!authenticated && userAttributes.isDoctor !== null
            ? t("core:checkingPermissionStatus")
            : t("core:forMedicalInstitutions")}
        </h1>
      </Layout>
    );
  } else {
    // load org details
    getConfig();

    return (
      <ConfigProvider locale={i18n.language === "de" ? deDe : enUs}>
        <Provider
          stores={stores}
          uiStore={uiStore}
          authStore={authStore}
          organisationManagementStore={organisationManagementStore}
          patientCareStore={patientCareStore}
          messagingStore={messagingStore}
          therapyStore={therapyStore}
          medicationTherapyStore={medicationTherapyStore}
          hcpStore={hcpStore}
        >
          <Layout className="dtb-layout">
            <BrowserRouter>
              <Header className="dtb-header">
                <NavBar
                  authStore={authStore}
                  organisationManagementStore={organisationManagementStore}
                  showNav={showNav}
                  showDetail={showDetail}
                  isMobileIconVisible={isMobileIconVisible}
                />
              </Header>
              <div className="dtb-main-content">
                <Routes>
                  {/**
                   * @description route config: accept all user if auth
                   */}
                  <Route key="1" path="/about" element={<About />} />
                  <Route key="2" path="/imprint" element={<Imprint />} />
                  {/*<Route key="2" path="/profil" element={<Profil />} />*/}
                  {/**
                   * @description route config: only auth true and attribute isDoctor true
                   */}
                  {authenticated && userAttributes.isDoctor ? (
                    <Route
                      key="3"
                      path="/*"
                      element={
                        <PatientManagement
                          detailInfoVisible={detailInfoVisible}
                          onCloseDetail={onCloseDetail}
                        />
                      }
                    />
                  ) : (
                    <Route
                      key="4"
                      path="/*"
                      element={
                        <Layout className="dtb-main-layout">
                          <WarningOutlined
                            style={{ fontSize: 100, color: "red" }}
                          />
                          <h3
                            style={{
                              marginTop: 20,
                              fontSize: 20,
                            }}
                          >
                            {t("core:noPermissionWarning")}
                          </h3>
                        </Layout>
                      }
                    />
                  )}
                  <Route key="4" path="*" element={<>error</>} />
                </Routes>
                <Drawer
                  title="Patientenbetreuung"
                  width={200}
                  closable={false}
                  onClose={onCloseNav}
                  open={navVisible}
                  placement={"left"}
                >
                  <div>
                    <Menuw theme="light" />
                  </div>
                </Drawer>
              </div>
            </BrowserRouter>
          </Layout>
        </Provider>
      </ConfigProvider>
    );
  }
};

export default withTranslation("core")(observer(App));
