export default {
  patient: 'Patient',
  medInTake: 'Medikamenten-einnahme',
  therapyPlan: 'Therapieplan',
  therapyCycle: 'Therapiezyklus',
  day: 'Tag',
  sideEffects: 'Nebenwirkungen',
  chat: 'Chat',
  adherence: {
    forgot: 'vergessen',
    Vergessen: 'vergessen',
    completed: 'Genommen',
    noCurrentData: 'Eingabe zurückliegend',
    noData: 'Bisher keine Eingabe vorhanden',
    stopped: 'Nicht (vollständig) genommen',
    title: 'Adhärenz',
    unknown: 'Unbekannt'
  },
  search: 'Suche',
  filterStatusTooltip: 'Archiv durchsuchen nach Status',
  noData: 'Keine Daten vorhanden'
};
