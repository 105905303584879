import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';
import config from './config';

var presenceChannel: PusherTypes.PresenceChannel;

/**
 * @description add pusher socket connection
 */
const pusher = new Pusher(config.pusher.channels.key, {
  cluster: config.pusher.channels.cluster
});

export default pusher;
