export default {
  patient: 'Patient',
  treatment: 'Behandlung',
  monitoring: 'Monitoring',
  communication: 'Kommunikation',
  dayplan: 'Tagesplan',
  processes: 'Prozesse',
  medication: 'Medikation',
  history: 'Verlauf',
  state: 'Status',
  adherence: 'Adhärenz',
  data: 'Daten',
  laboratoryValues: 'Laborwerte',
  careTeam: 'Betreungsteam'
};
