import { isEmpty } from 'lodash';
import { ISettingItem } from '../../../monitoring/models/components/ISettingItem';
import { IItemListViewMapper } from '../models/IItemListViewMapper';
import { IObservationItemVitalData } from '../models/IObservationItemVitalData';
import { vitalDataStatus } from './chartConfigVitalData';

/**
 * @function MedicationTherapyStore#helper/vitalDataToListViewMapper
 * @param observationData 
 * @param settingItem 
 * @returns 
 */
const vitalDataToListViewMapper = (
  observationData: IObservationItemVitalData[],
  settingItem: ISettingItem
): IItemListViewMapper[] => {
  const listData: IItemListViewMapper[] = observationData.map(
    (listItem: any): IItemListViewMapper => {
      // threshold settings
      const val1 = listItem.values[0] && listItem.values[0].value;
      const val2 = listItem.values[1] && listItem.values[1].value;
      // params options
      const { parameterDefinitions } =
        !isEmpty(listItem.vitalParameter) && listItem.vitalParameter;
      const parmDef = parameterDefinitions && parameterDefinitions[0];
      const unit =
        parmDef && ((parmDef.unit !== 'Smiley' && parmDef.unit) || '');
      let value = !isNaN(val1) ? val1 : '-';
      if (
        listItem.values.length > 1 &&
        !isEmpty(listItem.values[0]) &&
        !isEmpty(listItem.values[1])
      ) {
        const parm1 = listItem.values[0].parameterId;
        const val1Id = parm1.charAt(parm1.length - 1);
        // display values according their parm id 1/2
        value = val1Id === 1 ? value + ' / ' + val2 : value + ' / ' + val2;
      } else {
        value = val1;
      }

      return {
        createdAt: listItem.createdAt,
        validOnDateAt: listItem.validOnDateAt,
        value: value,
        yUnit: unit,
        status: vitalDataStatus(listItem, settingItem),
        x: listItem.validOnDateAt,
        y: val1,
        y2: val2,
        parameterDefinitions: parameterDefinitions,
        type: settingItem?.parameterType
      };
    }
  );

  return listData;
};

export default vitalDataToListViewMapper;
