import React, { FC } from 'react';
import { Avatar, Card, List, Skeleton } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ICaseCardPatient } from '../../models/components/ICaseCardPatient';

/**
 * ---
 * CaseCardPatient
 * ---
 * @class patientmanagement.components/CaseCardPatient
 * @type {React.FC<ICaseCardPatient>}
 * @return {React.ReactElement}
 */
const CaseCardPatient: FC<ICaseCardPatient> = ({
  patientCareStore,
  showSkeleton,
  size = 'default'
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Card
      className="dtb-case-card-patient"
      title={t('patient:patient')}
      size={size}
    >
      {showSkeleton ? (
        <>
          <br />
          <Skeleton
            loading={true}
            active
            avatar={{ size: 'default' }}
            paragraph={{ rows: 1 }}
          ></Skeleton>{' '}
        </>
      ) : (
        <>
          <List.Item.Meta
            avatar={
              <Avatar size={'default'}>
                {patientCareStore?.selectedCase &&
                  patientCareStore?.selectedCase?.patient?.firstName?.charAt(
                    0
                  ) +
                    patientCareStore?.selectedCase?.patient?.lastName?.charAt(
                      0
                    )}
              </Avatar>
            }
            title={
              <p>
                {`
                      ${patientCareStore?.selectedCase?.patient?.firstName} 
                      ${patientCareStore?.selectedCase?.patient?.lastName}
                    `}
              </p>
            }
            //description={item.description}
          />
          {/*t('patient:birthday'):{' '}*/}
          <span id="dtb-patient-date">
            {patientCareStore?.selectedCase?.patient?.birthdayString
              ? moment(
                  patientCareStore?.selectedCase?.patient?.birthdayString
                ).format('DD.MM.YYYY')
              : '-'}
          </span>
          {/* + ' '}
              | Geschlecht: {patientCareStore?.selectedCase?.patient?.gender}
              */}
        </>
      )}
    </Card>
  );
};

export default CaseCardPatient;
