import { isEmpty } from 'lodash';
import { splitDataToCharts } from './global';

/**
 * @function MedicationTherapyStore#helper/vitalDataToChartViewMapper
 * @param vitalParameter 
 * @param viewData 
 * @returns 
 */
const vitalDataToChartViewMapper = (vitalParameter: any, viewData: any) => {
  // disable if (isEmpty(observationsData) || isEmpty(viewData)) return null;
  // disable const { vitalParameter } = observationsData[0];
  const { threshold } = !isEmpty(vitalParameter) && vitalParameter;
  // possible value for lineSerie and markSeries one
  const paramDef1 = !isEmpty(threshold) && threshold[0];
  // possible value for lineSerie and markSeries two
  const paramDef2 = !isEmpty(threshold) && threshold[1];
  const { lineSeries, markSeries }: any = splitDataToCharts(viewData);

  const hasSecondChartData = viewData.some((item: any) => item.y2);
  const { lineSeries2, markSeries2 } =
    hasSecondChartData && splitDataToCharts(viewData, true);

  const chartData = {
    lineSeries,
    markSeries,
    lineSeries2,
    markSeries2,
    paramDef1,
    paramDef2,
    step: 1,
    min: 0,
    max: 500,
    min2: 0,
    max2: 500,
    isSideEffect: false
  };
  return chartData;
};

export default vitalDataToChartViewMapper;
