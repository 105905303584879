import { observable, makeObservable, computed, action } from 'mobx';

export class UIStore {
  @observable
  private caseViewMobil: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @computed
  get getCaseViewMobil() {
    return this.caseViewMobil;
  }

  @action
  setCaseViewMobil = (option: boolean) => {
    this.caseViewMobil = option;
  };
}
