import moment from "moment";
import { isEmpty } from "lodash";

/**
 * @function patientmanagement#services/TherapyState
 * @description this function is to check if a currenty therapy plan is
 * a) ongoing
 * b) in teh past
 * c) planed (not started yet)
 * @param email
 * @returns
 */
export const TherapyState = ({ startAt, endAt }: any) => {
  if (isEmpty(startAt) || isEmpty(endAt)) return "noStateData";

  const today = moment(new Date(), "DD/MM/YYYY");
  const tomorrow = moment(today).subtract(1, "days");
  const startDay = moment(new Date(startAt), "DD/MM/YYYY");
  const endDay = moment(new Date(endAt), "DD/MM/YYYY");
  let therapyState = "ongoing";
  therapyState =
    !!startAt && moment(startDay).isAfter(today) ? "future" : therapyState;
  // use tomorrow to fix isBefore check, which was true by today
  therapyState =
    !!endAt && moment(endDay).isBefore(tomorrow) ? "completed" : therapyState;

  return therapyState;
};

/**
 * @function patientmanagement#services/TherapyCycleDay
 * @param param0
 * @returns
 */
export const TherapyCycleDay = ({ startAt }: any) => {
  if (isEmpty(startAt)) return null;

  const startDay = moment(new Date(startAt), "DD/MM/YYYY");
  const today = moment(new Date(), "DD/MM/YYYY");
  return !!startDay && today.diff(startDay, "days") + 1;
};
