export default {
  firstName: 'Firstname',
  lastName: 'Lastname',
  gender: 'Gender',
  male: 'male',
  female: 'female',
  diverse: 'divers',
  birthday: 'Birthday',
  selectDate: 'Select date',
  decline_to_specify: 'Keine Angabe',

  size: 'Size',
  weight: 'Weight',

  phone: 'Telephone',
  mobilePhone: 'Mobile phone',
  email: 'E-Mail',

  homeAddress: 'Home Address',
  streetNumber: 'Street, Number',
  zip: 'Zip',
  zipCity: 'Zip, City',
  city: 'City',
  contact: 'Contact',
  country: 'Country',

  name: 'Name',
  insurance: 'Insurance',
  insuranceNumber: 'Insurance number',
  insuranceType: 'Insurance typ',
  private: 'private',
  public: 'public',

  zuzahlungsfrei: 'co-payments free',
  zuzahlungspflichtig: 'liable to co-payments',

  patient: 'Patient',
  info: 'Patient information',
  informationRequired: 'Please fill out',
  noAdditionalData: 'no data found',
  additionalData: 'Additional',

  bodyHeight: 'Body height',
  bodyWeight: 'Body weight',
  bodySurface: 'Body surface',

  save: 'Save',
  cancel: 'Cancel',
  edit: 'Edit',

  treatment: 'Treatment',
  treatmentId: 'Treatment-ID',
  description: 'Description'
};
