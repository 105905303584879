const config = {
  keycloak: {
    clientId: 'hub-connect',
    realm: 'comjoo-hub',
    url: 'https://sso.tino-dtb.com/auth'
  },
  io: {
    url: 'https://medipolis.socket.api.tino-dtb.com',
    path: '/socket.io'
  },
  jitsimeet: {
    domain: 'meet.tino-dtb.com',
    libraryName: 'external_api.min.js',
    config: {}
  },
  patientManagement: {
    url: 'https://pm-doc.tino-dtb.com'
  },
  medicationTherapyManagement: {
    url: 'https://medication-doc.tino-dtb.com'
  },
  therapyDiagnoses: {
    url: 'https://therapy-api.tino-dtb.com'
  },
  dtbplaner: {
    url: 'https://therapieplaner.tino-dtb.com'
  },
  process: {
    url: 'https://process-api.tino-dtb.com'
  },
  meet: {
    url: 'https://meet-development.tino-dtb.com'
  },
  pusher: {
    channels: {
      appId: '1363788',
      key: 'f74bb9dcab6361797975',
      cluster: 'eu'
    }
  }
};

export default config;
