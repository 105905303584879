export default {
  ongoing: 'Laufend',
  planned: 'Geplant',
  started: 'Gestartet',
  completed: 'Vollendet',
  done: 'Vollendet',
  completed_report_started: 'Gestartet',
  result_available: 'Ergebnis verfügbar',
  aborted_done: 'Abgebrochen Beendet',
  on_hold: 'Wartend',
  not_assigned: 'Nicht zugewiesen',
  aborted: 'Abgebrochen',
  noData: 'Keine Therapieinformationen verfügbar'
};
