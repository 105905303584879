import { action, computed, makeObservable, observable } from 'mobx';
import { patientManagementClient } from '../../axios';

/**
 * MobX OrganisationManagementStore
 * @property {null | any} orgConfig - private observable
 */
export class OrganisationManagementStore {
  @observable
  private orgConfig = null;

  constructor() {
    makeObservable(this);
  }

  /**
   * @method OrganisationManagementStore.getOrgConfig
   * @returns {null | any}
   */
  @computed
  get getOrgConfig() {
    return this.orgConfig;
  }

  /**
   * @description OrganisationManagementStore.setter/setOrgConfig
   */
  @action
  setOrgConfig(entry: any): void {
     this.orgConfig = entry
  }

  /**
   * @method OrganisationManagementStore.api/getConfigToOrganization
   * @param {string} organizationId
   */
  @action.bound async getConfigToOrganization(organizationId: string) {
    try {
      const res: any = await patientManagementClient.get<any>(
        `/organization/config/${organizationId}`
      );

      this.setOrgConfig(res.data[0]);
      return res;
    } catch (e) {
      return e;
    }
  }
}
