export default {
  checkingPermissionStatus: 'Checking permission status…',
  forMedicalInstitutions: 'FOR MEDICAL INSTITUTIONS…',
  noPermissionWarning: 'This application is only permitted for medical personnel.',
  goodMorning: 'Good Morning',
  goodEvening: 'Good Evening',
  hello: 'Hello',
  header: { subtitle: 'for medical facilities' },
  noNotifications: 'No new notifications'
};
