export default {
  patient: 'Patient',
  medInTake: 'Use of medicines',
  therapyPlan: 'Therapy plan',
  therapyCycle: 'Therapy cycle',
  day: 'Day',
  sideEffects: 'Side effects',
  chat: 'Chat',
  adherence: {
    completed: 'All taken',
    noCurrentData: 'No current entry',
    noData: 'No submited data',
    stopped: 'Not (all) taken',
    title: 'Adherence',
    unknown: 'Unknown'
  },
  search: 'Search'
};
