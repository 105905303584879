import moment from 'moment';

/**
 * @function patientmanagement#services/getDisabledBirthdays
 * @param current 
 * @returns 
 */
const getDisabledBirthdays = (current: any) => {
  // Can not select days of today or after
  return current && current > moment().endOf('day');
};

export default getDisabledBirthdays;
