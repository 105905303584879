import axios from 'axios';
import config from './config';
import keycloak from './keycloak';

/**
 * @description patientManagement client with interceptors
 */
const patientManagementClient = axios.create({
  baseURL: config.patientManagement.url
});

/**
 * @description patientManagement request interceptors
 */
patientManagementClient.interceptors.request.use(
  (request: any) => {
    request.headers.common.Authorization = `Bearer ${keycloak.token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description patientManagement response interceptors
 */
patientManagementClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description medicationTherapyClient client with interceptors
 */
const medicationTherapyClient = axios.create({
  baseURL: config.medicationTherapyManagement.url
});

medicationTherapyClient.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
/**
 * @description medicationTherapyClient request interceptors
 */
medicationTherapyClient.interceptors.request.use(
  (request: any) => {
    request.headers.common.Authorization = `Bearer ${keycloak.token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description medicationTherapyClient response interceptors
 */
medicationTherapyClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description therapyClient client with interceptors
 */
const therapyClient = axios.create({
  baseURL: config.therapyDiagnoses.url
});

/**
 * @description therapyClient request interceptors
 */
therapyClient.interceptors.request.use(
  (request: any) => {
    request.headers.common.Authorization = `Bearer ${keycloak.token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description therapyClient response interceptors
 */
therapyClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { patientManagementClient, medicationTherapyClient, therapyClient };
