export default {
  therapyManager: 'Therapy planner',
  profil: 'Profil',
  logout: 'Logout',
  about: 'About Tino DTB',
  imprint: 'Imprint',
  titlePatientCare: 'Patient care',
  treatment: 'Treatment',
  archive: 'Archive'
};
