import { type } from 'os';
import { IItemListViewMapper } from '../models/IItemListViewMapper';
import { IObservationItemSideEffects } from '../models/IObservationItemSideEffects';

/**
 * @function MedicationTherapyStore#helper/sideEffectsToListViewMapper
 * @param observationData 
 * @returns 
 */
const sideEffectsToListViewMapper = (
  observationData: IObservationItemSideEffects[]
): IItemListViewMapper[] => {
  const listData: IItemListViewMapper[] = observationData.map(
    (item: IObservationItemSideEffects): IItemListViewMapper => {
      const { createdAt, value, validOnDateAt, type } = item;
      return {
        createdAt: createdAt,
        validOnDateAt: validOnDateAt,
        value: value,
        x: validOnDateAt,
        y: value,
        status: value,
        type: type
      };
    }
  );
  return listData;
};

export default sideEffectsToListViewMapper;
