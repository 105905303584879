import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import findPatientItem from '../../services/findpatientItem';

/**
 * @memberof patientmanagement#templates/patientInsuranceTemplate
 * @interface IPatientInsuranceTemplate
 */
type IPatientInsuranceTemplate = {
  t: Function;
  patient: any;
  viewMode: boolean;
  form: any;
  requiredInsuranceNumber: boolean;
  setRequiredInsuranceNumber: Function;
};

/**
 * @function patientmanagement#templates/patientInsuranceTemplate
 * @description 
   - insuranceName
   - insuranceNumber
   - insuranceType
 * @param {IPatientInsuranceTemplate} obj
 * @returns 
 */
const patientInsuranceTemplate = ({
  t,
  patient,
  viewMode,
  form,
  requiredInsuranceNumber,
  setRequiredInsuranceNumber
}: IPatientInsuranceTemplate) => {
  let typeRequired = !viewMode
    ? form.getFieldValue('insuranceType') !== 'private'
      ? true
      : false
    : false;
  let patientObj: any;
  let insurance: any = {};

  const validateWithInsuranceType = (
    rule: any,
    value: any,
    callback: Function
  ) => {
    setTimeout(() => {
      if (
        requiredInsuranceNumber &&
        form.getFieldValue('insuranceNumber') === 0
      ) {
        callback('Error!');
      } else {
        callback();
      }
    }, 200);
  };

  if (patient !== null && !isEmpty(patient)) {
    insurance =
      patient?.length > 0
        ? findPatientItem(patient, 'PatientInsurance')
        : patient;
    const { insuranceName, insuranceNumber, insuranceType } =
      !isEmpty(insurance) && insurance.content;

    patientObj = {
      insuranceName:
        !isEmpty(insurance) && insuranceName?.length > 0 ? insuranceName : '-',
      insuranceNumber:
        insuranceNumber?.length > 0 ? insuranceNumber : viewMode ? '-' : '',
      insuranceType:
        insuranceType?.length > 0
          ? viewMode
            ? t(`patient:${insuranceType}`)
            : insuranceType
          : viewMode
          ? '-'
          : ''
    };

    if (!viewMode) {
      if (
        (insuranceType === 'public' &&
          form.getFieldValue('insuranceType') === undefined) ||
        form.getFieldValue('insuranceType') === 'public'
      ) {
        typeRequired = true;
      } else {
        typeRequired = false;
      }
      //if (insuranceType != 'private' && insuranceType == 'public' && form.getFieldValue('insuranceType') == undefined) {
      //  typeRequired = true;
      //}
    }
  } else {
    patientObj = {
      insuranceName: isEmpty(insurance) && viewMode ? '-' : '',
      insuranceNumber: isEmpty(insurance) && viewMode ? '-' : '',
      insuranceType: isEmpty(insurance) && viewMode ? '-' : ''
    };
  }

  const onchange = (e: any) => {
    if (e === 'private') {
      setRequiredInsuranceNumber(false);
    } else {
      setRequiredInsuranceNumber(true);
    }
  };

  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    initialValues: {
      ...patientObj
    },
    fields: [
      {
        key: 'label5',
        colSpan: 4,
        render() {
          return (
            <fieldset>
              <legend>{t('patient:insurance')}</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'insuranceName',
        label: t('patient:name'),
        colSpan: 2,
        required: true,
        message: t('patient:requiredField')
      },
      {
        key: 'insuranceNumber',
        label: t('patient:insuranceNumber'),
        colSpan: 2,
        required: typeRequired,
        message: t('patient:requiredField'),
        rules: [
          {
            required: typeRequired,
            validator: validateWithInsuranceType
          }
        ]
      },
      {
        key: 'insuranceType',
        label: t(`patient:insuranceType`),
        colSpan: 2,
        required: true,
        message: t('patient:requiredField'),
        widget: 'select',
        widgetProps: {
          onChange: onchange
        },
        options: [
          { label: t('patient:private'), value: 'private' },
          { label: t('patient:public'), value: 'public' }
        ]
      }
    ]
  };

  return meta;
};

export default patientInsuranceTemplate;
