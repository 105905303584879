import { isEmpty } from 'lodash';

/**
 * @function patientmanagement#services/findPatientItem
 * @param itemsList 
 * @param typeName 
 * @returns 
 */
const findPatientItem = (itemsList: any, typeName: any) =>
  itemsList.find(
    (item: any) => !isEmpty(item.content) && item.content.type === typeName
  );

export default findPatientItem;
