import React, { useState, createElement } from "react";
import { observer } from "mobx-react";
import { useTranslation, withTranslation } from "react-i18next";
import { Menu, Typography } from "antd";
import { Link } from "react-router-dom";
// import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import { IHeader } from "../../models/components/IHeader";
import logoSrc from "../../../assets/logo.svg";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import menuTemplate from "../../templates/menu/menu";

const { Text } = Typography;

/**
 * ---
 * Header
 * ---
 * @class core.components/Header
 * @description global header layout
 * - show mobile hambuger menu (left and right)
 * - show logo and menu (left)
 * - show greeting with name and drop down menus (right)
 * @property {object} menuItem object menu with items
 * @type {React.FC<IHeader>}
 * @return {React.ReactElement}
 */
const Header = ({
  authStore,
  theme,
  showNav,
  showDetail,
  organisationManagementStore,
  isMobileIconVisible,
}: IHeader): JSX.Element => {
  const [collapsed, toggleMenu] = useState(false);
  const { t } = useTranslation();
  const { user, userAttributes } = authStore;
  const { goToProfil, logout } = authStore;
  const config = organisationManagementStore.getOrgConfig;

  /**
   * @method core.components/Header.getGreetingBasedOnTime
   * @description welcome text
   * @returns
   */
  const getGreetingBasedOnTime = (): string => {
    const d = new Date();
    const time = d.getHours();

    if (time >= 0 && time < 9) {
      return t("core:goodMorning");
    }

    if (time >= 9 && time < 18) {
      return t("core:hello");
    }

    if (time >= 18 && time < 24) {
      return t("core:goodEvening");
    }

    return t("core:hello");
  };

  /**
   * @property menuItems
   * @@description get menuItems
   */
  const menuItems = menuTemplate(t, user, goToProfil, logout);

  return (
    <div className="head-wrapper dtb-head-wrapper">
      <div>
        {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: "trigger",
          onClick: () => showNav(),
        })}

        <Link className="none-mobil dtb-logo-link" to="/">
          <img className="dtb-logo" src={logoSrc} alt="TinoDTB" height="23" />
          <span className="dtb-header-subtitle">
            {t("core:header.subtitle")}
          </span>
        </Link>

        {config !== null && userAttributes.isPlanner && (
          <a className="dtb-planner-link" href={`${config.planerHome}`}>
            {t("coreNav:therapyManager")}
          </a>
        )}
      </div>

      <div className="dtb-language-switch">
        {/* <LanguageSwitch /> */}
        <Text className="dtb-grettings">
          {getGreetingBasedOnTime() +
            ", " +
            authStore.user.firstName +
            " " +
            authStore.user.lastName}
        </Text>

        <Menu
          theme="dark"
          mode="horizontal"
          selectable={false}
          items={menuItems}
        />

        {isMobileIconVisible &&
          React.createElement(
            collapsed ? MenuFoldOutlined : MenuUnfoldOutlined,
            {
              className: "trigger-two",
              style: { float: "right" },
              onClick: () => showDetail(),
            }
          )}
      </div>
    </div>
  );
};

export default withTranslation("core")(observer(Header));
