import { isEmpty } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { patientManagementClient } from '../../axios';
import { MedicationTherapyStore } from '../MedicationTherapyStore/MedicationTherapyStore';
import ICases from './models/ICases';
import { IPatientContentValues } from './models/IPatientContentValues';

/**
 * MobX PatientCareStore
 * @property {ICases} cases - private observable
 * @property {boolean} casesLoaded - private observable
 * @property {boolean} casesLoadedMore - private observable
 * @property {number} casesLoadedMoreSkip - private observable
 * @property {boolean} allCasesLoaded - private observable
 * 
 * @property {ICases} casesArchive - private observable
 * @property {boolean} casesArchiveLoaded - private observable
 * @property {string} casesArchiveFilter - private observable
 * 
 * @property {any} selectedCase - private observable
 * @property {boolean} caseLoaded - private observable
 * @property {any} warn - private observable
 * @property {string} searchCases - private observable
 * @property {any} case - private observable
 * @property {any} patient - private observable
 * @property {boolean} patientLoaded - private observable
 */
export class PatientCareStore {

  ///////////////////
  // cases overview
  ///////////////////

  @observable
  private cases: ICases[] = [];
  
  @observable
  private casesLoaded: boolean = false;
  
  @observable
  private casesLoadedMore: boolean = false;
  
  @observable
  private casesLoadedMoreSkip: number = 20;
  
  @observable
  private allCasesLoaded: boolean = false;

  ///////////////////
  // cases archive overview
  ///////////////////

  @observable
  private casesArchive: ICases[] = [];
  
  @observable
  private casesArchiveLoaded: boolean = false;
  
  @observable
  private casesArchiveFilter: string = 'DONE';

  ///////////////////
  // case
  ///////////////////
  @observable
  private selectedCase: any = null;

  @observable
  private caseLoaded: boolean = false;
 
  ///////////////////
  // other
  ///////////////////

  @observable
  public warn = [];
  @observable
  private searchCases: string = '';
  @observable
  private case: any = null;
  @observable
  private patient: any = null;
  @observable
  private patientLoaded: boolean = false;

  // dependencies stores
  private medicationTherapyStore;

  constructor() {
    makeObservable(this);
    this.medicationTherapyStore = new MedicationTherapyStore();
  }

  /**
   * @method PatientCareStore.getter/getCases
   * @description getter cases
   */
  @computed
  public get getCases() {
    return this.cases;
  }

  /**
   * @method PatientCareStore.setter/setCases
   * @description setter cases
   */
  @action.bound
  public setCases = (data: any) => {
    // default filter config
    this.cases = this.sortDescending(data, 'updatedAt');
  };

  /**
   * @method PatientCareStore.getter/getCasesLoaded
   * @description getter casesLoaded
   * @return {observable} observable stream
   * @observable
   */
  @computed
  public get getCasesLoaded() {
    return this.casesLoaded;
  }

  /**
   * @method PatientCareStore.setter/setCasesLoading
   * @description setter casesLoaded
   * @param value
   */
  @action.bound
  public setCasesLoading = (value: boolean) => {
    this.casesLoaded = value;
  };

  /**
   * @method PatientCareStore.getter/getAllCasesLoaded
   * @description getter allCasesLoaded
   * @return {observable} observable stream
   */
  @computed
  public get getAllCasesLoaded(): boolean {
    return this.allCasesLoaded;
  }

  /**
   * @method PatientCareStore.setter/setAllCasesLoaded
   * @description setter setAllCasesLoaded
   * @param option
   */
  @action
  public setAllCasesLoaded = (option: boolean): void => {
    this.allCasesLoaded = option;
  };

  /**
   * @method PatientCareStore.getter/getCasesArchive
   * @description getter casesArchive
   * @return {observable} observable stream
   */
  @computed
  public get getCasesArchive() {
    return this.casesArchive;
  }

  /**
   * @method PatientCareStore.setter/setCasesArchive
   * @description default filter config
   * @param data
   */
  @action.bound
  public setCasesArchive = (data: any) => {
    this.casesArchive = this.sortDescending(data, 'updatedAt');
  };

  /**
   * @method PatientCareStore.getter/casesArchiveLoaded
   * @description getter casesArchiveLoaded
   * @return {observable} observable stream
   */
  @computed
  public get getCasesArchiveLoaded() {
    return this.casesArchiveLoaded;
  }

  /**
   * @method PatientCareStore.setter/setCasesArchiveLoaded
   * @param value
   */
  @action.bound
  public setCasesArchiveLoaded = (value: boolean) => {
    this.casesArchiveLoaded = value;
  };

  /**
   * @method PatientCareStore.getter/getCasesLoadedMore
   * @description getter casesLoadedMore
   * @return {observable} observable stream
   */
  @computed
  public get getCasesLoadedMore() {
    return this.casesLoadedMore;
  }

  /**
   * @method PatientCareStore.getter/getCasesArchiveFilter
   * @description getter casesArchiveFilter
   * @return {observable} observable stream
   */
  @computed
  public get getCasesArchiveFilter() {
    return this.casesArchiveFilter;
  }

  /**
   * @method PatientCareStore.getter/getCaseLoaded
   * @description getter caseLoaded
   * @return {observable} observable stream
   */
  @computed
  public get getCaseLoaded() {
    return this.caseLoaded;
  }

  /**
   * @method PatientCareStore.getter/getCaseEntry
   * @description getter selectedCase
   * @return {observable} observable stream
   */
  @computed
  public get getCaseEntry() {
    return this.selectedCase;
  }

  /**
   * @method PatientCareStore.setter/setCaseEntry
   * @description setter setCaseEntry
   * @param data
   */
  @action
  private setCaseEntry(data: any) {
    this.selectedCase = data;
  }

  /**
   * @method PatientCareStore.setter/setFilterTreatmentState
   * @param filter
   */
  @action
  public setFilterTreatmentState(filter: any) {
    this.casesArchiveFilter = filter;
  }

  /**
   * @method PatientCareStore.api/fetchCases
   * @description get cases with subdata infos
   * @param searchValue
   */
  @action.bound
  public fetchCases = async (searchValue: string = ''): Promise<any> => {
    try {
      const res: any = await patientManagementClient.get<ICases>(
        `/cases?relations[]=patient&take=20&skip=0&treatmentState=${'NOT_ASSIGNED'}&isDigitalCare=false&search=${searchValue}` //&treatmentState=${'IN_PROGRESS'}
      );
      //this.cases = res.data[0];
      const extendedCasesInfos: any = await this.loadCasesSubData(res.data[0]);
      this.setCases(extendedCasesInfos);
      this.setCasesLoading(true);
    } catch (e) {
      return e;
    }
  };

  /**
   * @method PatientCareStore.api/fetchArchivedCases
   * @description fetch archive cases with subdata infos
   * @param search
   * @return []
   */
  @action
  public fetchArchivedCases = async (search: string = ''): Promise<any> => {
    try {
      const res: any = await patientManagementClient.get<ICases[]>(
        `/cases?take=20&skip=0&search=${search}&treatmentState=${this.casesArchiveFilter}`
      );
      this.casesArchive = res.data[0];
      // load subdata cases overview archive
      const extendedCasesInfos: any = await this.loadCasesSubData(res.data[0]);
      this.setCasesArchive(extendedCasesInfos);
      this.setCasesArchiveLoaded(true);
      return res.data[0];
    } catch (e) {
      return e;
    }
  };

  /**
   * @method PatientCareStore.api/loadCasesSubData
   * @description helper load cases overview with subdata here
   * - medPlans
     - adherence list
     - counter: message counter
     - medication lists
     - alarmThresholdReview sideeffects list
   * @param list
   */
  @action
  private loadCasesSubData = async (list: any): Promise<any> => {
    let entries: any = [];
    await Promise.all(
      await list.map(async (entry: any, index: number) => {
        let medPlans: any = null;
        let adherence: any = null;
        let counter: any = null;
        let medication: any = null;
        let alarmThresholdReview: any = [];

        // load current medicationplan
        medPlans = await this.medicationTherapyStore.fetchCurrentMedPlan(
          entry.patientId
        );

        // load medication in table
        adherence = await this.medicationTherapyStore.fetchMedicationInTake(
          entry.patientId
        );

        // load unread messages counter
        counter = await this.fetchUnreadMessageCount(entry.messageProcessId);

        let temp: any = null;
        let medicationData: any = null;
        // load side effects
        if (
          medPlans !== null &&
          medPlans.status === 200 &&
          !isEmpty(medPlans) &&
          medPlans !== 'undefined' &&
          medPlans.data[1] > 0
        ) {
          medication = await this.medicationTherapyStore.fetchSideEffectsSettings(
            entry.patientId,
            medPlans.data[0][0].id
          );
          const medication2 = await this.medicationTherapyStore.fetchVitalDataSettings(
            entry.patientId,
            medPlans.data[0][0].id
          );

          let data = await this.medicationTherapyStore.fetchAlarmtTresholdReviewDaySideEffects(
            medPlans.data[0][0].id,
            entry.patientId
          );

          alarmThresholdReview = data ? data : [];
        }

        // build case with obj and push in new array cases
        let item = {
          ...entry,
          adherence: adherence.hasOwnProperty('data') ? adherence?.data[0] : [],
          warnings:
            medPlans.status === 200 && medPlans.data[1] > 0
              ? medPlans.data[0][0].warnings
              : [],
          currentTherapyPlan:
            medPlans.status === 200 && medPlans.data[1] > 0
              ? medPlans.data[0][0]
              : [],
          medPlans: medPlans.status === 200 && medPlans.data[0],
          alarmThresholdReview: alarmThresholdReview,
          sideEffects:
            medication && medication.status === 200 ? medication.data : [],
          notificationsCount: counter !== undefined ? counter : 0,
          ...medication
        };

        entries.push(item);
      })
    );
    return entries;
  };

  /**
   * @method PatientCareStore.api/getMoreCases
   * @return
   */
  @action
  public getMoreCases = async (): Promise<any> => {
    if (this.casesLoadedMore) {
      return;
    } else {
      this.casesLoadedMore = true;
      const newData = await this.fetchMoreCases(
        20,
        this.casesLoadedMoreSkip,
        ''
      );
      const extendedCasesInfos: any = await this.loadCasesSubData(newData);
      const tempData = [...this.cases, ...extendedCasesInfos];

      setTimeout(() => {
        this.setCases(tempData);
      }, 200);

      this.casesLoadedMoreSkip = this.casesLoadedMoreSkip + 20;
      this.casesLoadedMore = false;
    }
    return;
  };

  /**
   * @method PatientCareStore.api/fetchMoreCases
   * @param take
   * @param skip
   * @param searchValue
   * @return
   */
  @action.bound
  public fetchMoreCases = async (
    take: number = 20,
    skip: number = 0,
    searchValue: string = ''
  ): Promise<any> => {
    try {
      const res: any = await patientManagementClient.get<ICases>(
        `/cases?relations[]=patient&take=${take}&skip=${skip}&treatmentState=NOT_ASSIGNED&isDigitalCare=false&search=${searchValue}`
      );
      if (res.data[1] === 0) {
        this.setAllCasesLoaded(true);
      }
      return res.data[0];
    } catch (e) {
      return e;
    }
  };

  /**
   * @method PatientCareStore.sortDescending
   * @param array
   * @param sortBy
   * @return
   */
  private sortDescending(array: any, sortBy: string) {
    return array.sort(
      (a: any, b: any) => +moment(b[sortBy]) - +moment(a[sortBy])
    );
  }

  /**
   * @method PatientCareStore.api/fetchCase
   * @description get case with patient data infos
   * @param caseId
   * @return
   */
  @action
  public fetchCase = async (caseId: string): Promise<any> => {
    if (this.caseLoaded) {
      return;
    }
    try {
      this.caseLoaded = true;
      const res: any = await patientManagementClient.get<any>(
        `/cases/${caseId}?relations[]=patient`
      );

      this.caseLoaded = false;
      this.setCaseEntry(res.data);

      /**
       * @description deprecated remove in v1.5.0
       */
      // load current med plans

      //const medPlans = await this.medicationTherapyStore.fetchCurrentMedPlan(
      //  res.data.patientId
      //);
      /*
      if (medPlans?.data?.[0]?.[0]?.id !== undefined) {
        const medi = await this.medicationTherapyStore.fetchMedication(
          medPlans.data[0][0].id
        );
        // load medication in tale
        const adherence = await this.medicationTherapyStore.fetchMedicationInTake(
          res.data.patientId
        );
      }
      */
      let tempData = {
        ...res.data
      };

      this.setCaseEntry({ ...this.selectedCase, ...res.data, ...tempData });
      this.case = res.data;

      return res.data;
    } catch (e) {
      return e;
    }
  };

  /**
   * @method PatientCareStore.api/fetchUnreadMessageCount
   * @param {string} messageProcessId
   * @return []
   */
  @action
  public fetchUnreadMessageCount = async (
    messageProcessId: string
  ): Promise<any> => {
    try {
      const { data }: any = await patientManagementClient.get<any>(
        `/process/${messageProcessId}`
      );
      return data ? data.orgUnreadMessages : '';
    } catch (e) {
      return e;
    }
  };

  /**
   * @method PatientCareStore.api/fetchPatientData
   * @description Update data from patient profile
   * @param {string} patientId
   */
  @action.bound
  public async fetchPatientData({
    patientId
  }: {
    patientId: string;
  }): Promise<any> {
    if (this.patientLoaded) {
      return;
    }
    try {
      const { data }: any = await patientManagementClient.get<any>(
        `/patients/${patientId}/data`
      );
      this.patient = data[0];
      this.patientLoaded = true;
    } catch (e) {
      return e;
    }
  }

  /**
   * @method PatientCareStore.clearPatientData
   * @description clear local state patient data
   */
  @action
  public clearPatientData() {
    this.patient = null;
    this.patientLoaded = false;
  }

  /**
   * @method PatientCareStore.api/patchPatientItem
   * @description patch or post data patient
   * @param param0
   */
  @action.bound
  public async patchPatientItem({
    patientId,
    contentValues
  }: {
    patientId: string;
    contentValues: IPatientContentValues;
  }): Promise<any> {
    try {
      const res: any = await patientManagementClient.patch<any>(
        `/patients/${patientId}`,
        {
          firstName: contentValues.firstName,
          lastName: contentValues.lastName,
          birthdayString: contentValues.birthdayString,
          phoneAccess: {
            phones: [
              { number: contentValues.phone, name: 'phone' },
              { number: contentValues.mobilephone, name: 'mobile' }
            ],
            type: 'PatientPhone'
          },
          insuranceCard: {
            insuranceName: contentValues.insuranceName,
            insuranceNumber: contentValues.insuranceNumber,
            insuranceType: contentValues.insuranceType,
            type: 'PatientInsurance'
          },
          address: {
            name: contentValues.firstName + ' ' + contentValues.lastName,
            address1: contentValues.homeAddress1,
            address2: contentValues.homeAddress2,
            zip: contentValues.homeZip,
            city: contentValues.homeCity,
            country: contentValues.homeCountry,
            type: 'PatientAddress'
          }
        }
      );
      const { data } = res;

      // Only set the results if defined otherwise we would change the initial
      if (data) {
        const { firstName, lastName, gender, birthdayString } = data;
        const updatedCase = {
          ...this.selectedCase,
          patient: data,
          patientFirstName: firstName,
          patientLastName: lastName,
          patientBirthdayString: birthdayString || null,
          patientGender: gender
        };
        this.selectedCase = updatedCase;
      }
      return res;
    } catch (e) {
      return false;
    }
  }

  /**
   * @method PatientCareStore.updateCaseItemList
   * @param warningIndex
   * @param caseIndex
   */
  @action
  public updateCaseItemList = (warningIndex: number, caseIndex: any) => {
    let entry = { ...this.cases[caseIndex] };
    entry?.currentTherapyPlan.warnings.splice(warningIndex, 1);

    this.cases[caseIndex] = { ...entry };
    this.cases[caseIndex].currentTherapyPlan = { ...entry.currentTherapyPlan };
    this.cases[caseIndex].currentTherapyPlan.warnings = [
      ...entry.currentTherapyPlan.warnings
    ];
  };
}
