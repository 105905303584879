import { isEmpty } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { medicationTherapyClient } from '../../axios';
import { IMedicationItem } from './models/IMedicationItem';
import { IObservationItemMapper } from './models/IObservationItemMapper';
import sideEffectsToListViewMapper from './config/sideEffectsToListViewMapper';
import sideEffectsToChartViewMapper from './config/sideEffectsToChartViewMapper';
import vitalDataToListViewMapper from './config/vitalDataToListViewMapper';
import vitalDataToChartViewMapper from './config/vitalDataToChartViewMapper';
import { ISetSettingStatus } from './models/ISetSettingStatus';
import { IObservationItemSideEffects } from './models/IObservationItemSideEffects';
import { IObservationItemVitalData } from './models/IObservationItemVitalData';

/**
 * MobX MedicationTherapyStore
 * @property {any} currentMedplan - private observable  medplan and medplans (is chosen is changeable in sidebar metplan)
 * @property {any} therapyPlans - private observable
 * @property {boolean} currentMedplanLoaded - private observable
 * @property {any} medplans - private observable medication data
 * @property {any} medication - private observable
 * @property {any} notTakenMed - private observable
 * @property {boolean} notTakenMedLoaded - private observable
 * 
 * @property {boolean} alarmtTresholdReviewSideEffects - private observable sideeffects and vitaldata settings
 * @property {boolean} alarmtTresholdReviewVitalData - private observable
 * @property {boolean} alarmtTresholdReviewServerStatusSideEffects - private observable
 * @property {boolean} alarmtTresholdReviewServerStatusVitalData - private observable
 * @property {boolean} sideEffectsSettings - private observable
 * @property {boolean} sideEffectsSettingsLoaded - private observable
 * @property {boolean} vitalDataSettings - private observable
 * @property {boolean} vitalDataSettingsLoaded - private observable
 * 
 * @property {string[]} observationsSideEffectsOpenItems - private observable only for ui is visble open if exist 
 * @property {string[]} observationsVitalDataOpenItems - private observable only for ui is visble open if exist
 * @property {string[]} observationsMedicationOpenItems - private observable only for ui is visble open if exist
 * 
 * @property {IObservationItemSideEffects[]} observationsSideEffectsItems - private observable
 * @property {IObservationItemVitalData[]} observationsVitalDataItems - private observable
 */

export class MedicationTherapyStore {
  
  @observable
  private currentMedplan: any = null;
  @observable
  private therapyPlans: any = [];
  @observable
  private currentMedplanLoaded: boolean = false;
 
  @observable
  private medplans: any = [];
  @observable
  private medication: any = [];
  @observable
  private notTakenMed: any = null;
  @observable
  private notTakenMedLoaded: boolean = false;

  @observable
  private alarmtTresholdReviewSideEffects = []; // side effect review
  @observable
  private alarmtTresholdReviewVitalData = []; // vital data review
  @observable
  alarmtTresholdReviewServerStatusSideEffects = {
    status: 200,
    message: ''
  }; // side effect review
  @observable
  alarmtTresholdReviewServerStatusVitalData = { status: 200, message: '' }; // vitaldata  review
  @observable
  private sideEffectsSettings: any = [];
  @observable
  private sideEffectsSettingsLoaded: boolean = false;
  @observable
  private vitalDataSettings: any = [];
  @observable
  private vitalDataSettingsLoaded: boolean = false;
 
  @observable
  private observationsSideEffectsOpenItems: string[] = [];
  @observable
  private observationsVitalDataOpenItems: string[] = [];
  @observable
  private observationsMedicationOpenItems: string[] = ['0', '1'];
  
  @observable
  private observationsSideEffectsItems = new Map<
    string,
    IObservationItemSideEffects
  >([]);
  @observable
  private observationsVitalDataItems = new Map<
    string,
    IObservationItemVitalData
  >([]);

  constructor() {
    makeObservable(this);
  }

  /**
   * @method MedicationTherapyStore.getter/getObservationsSideEffectsOpenItems
   * @description getter setter ui visible observations sideEffects collapse items string[]
   */
  @computed
  public get getObservationsSideEffectsOpenItems(): string[] {
    return this.observationsSideEffectsOpenItems;
  }

  /**
   * @method MedicationTherapyStore.setter/setObservationsSideEffectsOpenItems
   * @param entry 
   */
  @action
  public setObservationsSideEffectsOpenItems(entry: string[]): void {
    this.observationsSideEffectsOpenItems = entry;
  }

  /**
   * @method MedicationTherapyStore.getter/getObservationsVitalDataOpenItems
   * @description getter setter ui visible observations vitalData collapse items string[]
   * @return string[]
   */
  @computed
  public get getObservationsVitalDataOpenItems(): string[] {
    return this.observationsVitalDataOpenItems;
  }

  /**
   * @method MedicationTherapyStore.setter/setObservationsVitalDataOpenItems
   * @param entry 
   */
  @action
  public setObservationsVitalDataOpenItems(entry: string[]): void {
    this.observationsVitalDataOpenItems = entry;
  }

  /**
   * @method MedicationTherapyStore.getter/getObservationsMedicationOpenItems
   * @description getter setter ui visible observations vitalData collapse items string[]
   */
  @computed
  public get getObservationsMedicationOpenItems(): string[] {
    return this.observationsMedicationOpenItems;
  }

  /**
   * @method MedicationTherapyStore.setter/setObservationsMedicationOpenItems
   * @param entry 
   */
  @action
  public setObservationsMedicationOpenItems(entry: string[]): void {
    this.observationsMedicationOpenItems = entry;
  }

  /**
   * @method MedicationTherapyStore.getter/getObservationSideEffectsItems
   * @description getter, setter and single delete entry, map sideeffects observations, vitaldata observations
   */
  @computed
  public get getObservationSideEffectsItems(): any {
    return this.observationsSideEffectsItems;
  }

  /**
   * @method MedicationTherapyStore.setter/setObservationSideEffectsItems
   * @param id 
   * @param entry 
   */
  @action
  public setObservationSideEffectsItems(id: string, entry: any): void {
    this.observationsSideEffectsItems.set(id, entry);
  }

  /**
   * @method MedicationTherapyStore.clearSideEffectsObservation
   * @param id 
   */
  @action
  public clearSideEffectsObservation(id: string): void {
    this.observationsSideEffectsItems.delete(id);
  }

  /**
   * @method MedicationTherapyStore.getter/getObservationsVitalDataItems
   */
  @computed
  public get getObservationsVitalDataItems(): any {
    return this.observationsVitalDataItems;
  }

  /**
   * @method MedicationTherapyStore.setter/setObservationsVitalDataItem
   * @param id 
   * @param entry 
   */
  @action
  public setObservationsVitalDataItem(id: string, entry: any): void {
    this.observationsVitalDataItems.set(id, entry);
  }

  /**
   * @method MedicationTherapyStore.clearVitalDataObservation
   * @param id 
   */
  @action
  public clearVitalDataObservation(id: string): void {
    this.observationsVitalDataItems.delete(id);
  }

  /**
   * @method MedicationTherapyStore.clearObservations
   * @description clear all sideeffects and vitaldata observations
   */
  @action
  public clearObservations(): void {
    this.observationsSideEffectsItems.clear();
    this.observationsVitalDataItems.clear();
  }

  /**
   * @method MedicationTherapyStore.getter/getSideEffectSettings
   * @description getter setter and preloader SideEffectSettings
   */
  @computed
  public get getSideEffectSettings(): any {
    return this.sideEffectsSettings;
  }

  /**
   * @method MedicationTherapyStore.setter/setSideEffectSettings
   * @param entries 
   */
  @action
  public setSideEffectSettings(entries: any): void {
    this.sideEffectsSettings = entries;
  }

  /**
   * @method MedicationTherapyStore.getter/getSideEffectSettingsLoaded
   */
  @computed
  public get getSideEffectSettingsLoaded(): any {
    return this.sideEffectsSettingsLoaded;
  }

  /**
   * @method MedicationTherapyStore.setter/setSideEffectSettingsLoaded
   * @param option 
   */
  @action
  public setSideEffectSettingsLoaded(option: boolean): void {
    this.sideEffectsSettingsLoaded = option;
  }

  /**
   * @method MedicationTherapyStore.getter/getVitalDataSettings
   * @description getter setter and preloader VitalDataSettings
   */
  @computed
  public get getVitalDataSettings(): any {
    return this.vitalDataSettings;
  }

  /**
   * @method MedicationTherapyStore.setter/setVitalDataSettings
   * @param entries 
   */
  @action
  public setVitalDataSettings(entries: any): void {
    this.vitalDataSettings = entries;
  }

  /**
   * @method MedicationTherapyStore.getter/getVitalDataSettingsLoaded
   */
  @computed
  public get getVitalDataSettingsLoaded(): any {
    return this.vitalDataSettingsLoaded;
  }

  /**
   * @method MedicationTherapyStore.setter/setVitalDataSettingsLoaded
   * @param option 
   */
  @action
  public setVitalDataSettingsLoaded(option: boolean): void {
    this.vitalDataSettingsLoaded = option;
  }

  /**
   * @method MedicationTherapyStore.getter/getAlarmtTresholdReviewDaySideEffects
   * @description getter setter and preview SideEfffectsSettings and VitalDataSettings
   */
  @computed
  public get getAlarmtTresholdReviewDaySideEffects(): any {
    return this.alarmtTresholdReviewSideEffects;
  }

  /**
   * @method MedicationTherapyStore.setter/setAlarmtTresholdReviewDaySideEffects
   * @param entries 
   */
  @action
  public setAlarmtTresholdReviewDaySideEffects(entries: any): void {
    this.alarmtTresholdReviewSideEffects = entries;
  }

  /**
   * @method MedicationTherapyStore.getter/getAlarmtTresholdReviewDayVitalData
   */
  @computed
  public get getAlarmtTresholdReviewDayVitalData(): any {
    return this.alarmtTresholdReviewVitalData;
  }

  /**
   * @method MedicationTherapyStore.setter/setAlarmtTresholdReviewDayVitalData
   * @param entries 
   */
  @action
  public setAlarmtTresholdReviewDayVitalData(entries: any): void {
    this.alarmtTresholdReviewVitalData = entries;
  }

  /**
   * @method MedicationTherapyStore.getter/getTherapyPlansList
   * @description getter setter medications plans
   */
  @computed
  public get getTherapyPlansList(): any {
    return this.therapyPlans;
  }

  /**
   * @method MedicationTherapyStore.setter/setTherapyPlansList
   * @param entries 
   */
  @action
  public setTherapyPlansList(entries: any[] | null): void {
    this.therapyPlans = entries;
  }

  /**
   * @method MedicationTherapyStore.changeCurrentMedPlan
   * @description sidebar change chosen current medplan
   * @param id
   */
  @action
  public changeCurrentMedPlan = (id: string): void => {
    this.setCurrentMedplanLoaded(true);
    const object = this.therapyPlans.find((obj: any) => obj.id === id);
    this.setCurrentMedplan(object);
    this.setCurrentMedplanLoaded(false);
  };

  /**
   * @method MedicationTherapyStore.setter/setCurrentMedplanLoaded
   * @param option 
   */
  @action
  public setCurrentMedplanLoaded = (option: boolean): void => {
    this.currentMedplanLoaded = option;
  };

  /**
   * @method MedicationTherapyStore.getter/getCurrentMedplan
   * @description getter setter current medplan is chosen in system (can be change in sidebar),
   * ...reset ui and medplan data before change medplan
   */
  @computed
  public get getCurrentMedplan(): any {
    return this.currentMedplan;
  }

  /**
   * @method MedicationTherapyStore.setter/setCurrentMedplan
   * @param entry 
   */
  @action
  public setCurrentMedplan(entry: any): void {
    this.currentMedplan = entry;
  }

  /**
   * @method MedicationTherapyStore.getter/getMedicationOverview
   * @description getter setter medication overview
   */
  @computed
  public get getMedicationOverview(): any[] {
    return this.medication;
  }

  /**
   * @method MedicationTherapyStore.setter/setMedicationOverview
   * @param entry 
   */
  @action
  public setMedicationOverview(entry: any): void {
    this.medication = entry;
  }

  /**
   * @method MedicationTherapyStore.getter/getNoneTakenMeds
   * @description getter setter none taken meds
   */
  @computed
  public get getNoneTakenMeds(): any[] {
    return this.notTakenMed;
  }

  /**
   * @method MedicationTherapyStore.setter/setNoneTakenMeds
   * @param entries 
   */
  @action
  public setNoneTakenMeds(entries: any): void {
    this.notTakenMed = entries;
  }

  /**
   * @method MedicationTherapyStore.getPlanData
   * @description getPlan Datalists sideEffects and vitalData
   */
  @action.bound
  public getPlanData = (patientId: string, medPlanId: string): void => {
    this.fetchAlarmtTresholdReviewDaySideEffects(medPlanId, patientId);
    this.fetchAlarmtTresholdReviewDayVitalData(medPlanId, patientId);
  };

  /**
   * @method MedicationTherapyStore.pollingSideEffectsAndVitalDataObservations
   * @param patientId 
   * @param medicationPlanId 
   */
  @action pollingSideEffectsAndVitalDataObservations = (
    patientId: string,
    medicationPlanId: string
  ): void => {
    const observationsSideEffectsItems = this.observationsSideEffectsItems;
    const observationsVitalDataItems = this.observationsVitalDataItems;
    /**
     * @description array of object keys
     */
    Array.from(observationsSideEffectsItems.keys()).map(
      (sideEffect: string) => {
        this.clearSideEffectsObservation(sideEffect);
        this.loadSideEffectsObservations(
          patientId,
          { sideEffectId: sideEffect, medicationPlanId: medicationPlanId },
          {
            take: 50,
            skip: 0,
            gt: 0
          }
        );
      }
    );

    Array.from(observationsVitalDataItems.keys()).map((vitalData: string) => {
      this.clearVitalDataObservation(vitalData);
      this.loadVitalDataObservations(
        patientId,
        {
          vitalParameterId: vitalData,
          medicationPlanId: medicationPlanId
        },
        {
          take: 50,
          skip: 0,
          gt: 0
        }
      );
    });
  };

  /**
   * @method MedicationTherapyStore.api/loadSideEffectsObservations
   * @description load sideEffectsData and map data to view list chart list
   */
  @action
  public loadSideEffectsObservations = async (
    patientId: string,
    sideEffect: {
      sideEffectId: string;
      medicationPlanId: string;
    },
    addedData: any = { take: 50, skip: 0, gt: 0 },
    viewType?: 'list' | 'graph',
    viewTypeGraph?: 'month' | 'day'
  ): Promise<any> => {
    if (this.observationsSideEffectsItems.has(sideEffect.sideEffectId)) {
      return this.observationsSideEffectsItems.get(sideEffect.sideEffectId);
    } else {
      /**
       * @description load all observations
       */
      let list: any = [];
      let loading = true;
      let take = 50;
      let skip = 0;
      do {
        const res = await this.fetchSideEffectObservations(
          patientId,
          sideEffect,
          {
            take: take,
            skip: skip,
            gt: addedData.gt
          },
          viewType,
          viewTypeGraph
        );
        // loading = false;
        if (res !== null && res?.data?.[0]?.length > 0) {
          list = [...list, ...res?.data?.[0]];
        } else {
          list = [...list];
        }
        skip = skip + 50;
        if (res?.data?.[0].length === take) {
          loading = true;
        } else {
          loading = false;
        }
      } while (loading);

      /**
       * @description find obj with id setting threshold
       */
      let settingItem: any = this.alarmtTresholdReviewSideEffects?.find(
        (x: any) => x?.sideEffectId === sideEffect.sideEffectId
      );
      /**
       * @description create mapping list view
       */
      const listData: any = await sideEffectsToListViewMapper(list);

      /**
       * @description create mapping chart view
       */
      const chartData = sideEffectsToChartViewMapper(listData, settingItem);

      const convertToMappingSideEffects = {
        listData: listData, // list view all data
        chartData: chartData // chart view highest value from days
      };

      this.setObservationSideEffectsItems(
        sideEffect.sideEffectId,
        convertToMappingSideEffects
      );

      return list;
    }
  };

  /**
   * @method MedicationTherapyStore.api/loadVitalDataObservations
   * @description load vitalData and map data to view list chart list
   */
  @action
  public loadVitalDataObservations = async (
    patientId: string,
    vitalData: {
      vitalParameterId: string;
      medicationPlanId: string;
    },
    addedData: any = { take: 50, skip: 0, gt: 0 },
    viewType?: 'list' | 'graph',
    viewTypeGraph?: 'month' | 'day'
  ): Promise<any> => {
    let settingItem: any = this.alarmtTresholdReviewVitalData?.find(
      (x: any) => x?.vitalParameterId === vitalData?.vitalParameterId
    );

    if (this.observationsVitalDataItems.has(vitalData.vitalParameterId)) {
      return this.observationsVitalDataItems.get(vitalData.vitalParameterId);
    } else {
      /**
       * @description load all observations
       */
      let list: any = [];
      let loading = true;
      let take = 50;
      let skip = 0;

      do {
        const res = await this.fetchVitalDataObservations({
          patientId,
          medPlanId: vitalData.medicationPlanId,
          vitalItem: vitalData,
          addedData: {
            take: take,
            skip: skip,
            gt: addedData.gt
          },
          viewType,
          viewTypeGraph
        });
        if (res !== null && res?.data?.[0]?.length > 0) {
          list = [...list, ...res?.data?.[0]];
        } else {
          list = [...list];
        }
        skip = skip + 50;
        if (res?.data?.[0].length === take) {
          loading = true;
        } else {
          loading = false;
        }
      } while (loading);

      /**
       * @description create mapping für list view
       */
      const listData = vitalDataToListViewMapper(list, settingItem);
      /**
       * @description create mapping für chart view
       */
      const chartData: {
        lineSeries: [];
        lineSeries2: [];
        markSeries: [];
      } = vitalDataToChartViewMapper(settingItem, listData);
      const convertToMapping: IObservationItemMapper = {
        listData: listData, // Listenansicht
        chartData: {
          lineSeries: chartData.lineSeries, // chart js filter: max values from days
          lineSeries2: chartData.lineSeries2, // chart js filter: max values from days
          markSeries: chartData.markSeries, // chart js filter: not max values from days
          config: {
            double: settingItem?.threshold?.length > 1 ? true : false,
            settingItem,
            min: settingItem?.threshold?.[0]?.min
              ? parseInt(settingItem?.threshold?.[0]?.min)
              : parseInt(settingItem?.threshold?.[0]?.min),
            max: settingItem?.threshold?.[0]?.max
              ? parseInt(settingItem?.threshold?.[0]?.max)
              : parseInt(settingItem?.threshold?.[0]?.max),
            min2: settingItem?.threshold?.[0]?.min
              ? parseInt(settingItem?.threshold?.[0]?.min)
              : parseInt(settingItem?.threshold?.[0]?.min),
            max2: settingItem?.threshold?.[0]?.max
              ? parseInt(settingItem?.threshold?.[0]?.max)
              : parseInt(settingItem?.threshold?.[0]?.max),
            step: 1,
            isSideEffect: false
            // yUnit: 1
          }
        }
      };

      this.setObservationsVitalDataItem(
        vitalData.vitalParameterId,
        convertToMapping
      );
    }
  };

 
  ///////////////////
  //  FETCH / POST / PATCH / PUT DATA
  ///////////////////

  /**
   * @method MedicationTherapyStore.api/fetchCurrentMedPlan
   * @description retrieve the current ongoing therapy plan(s) also called medication plan
   * this can be one or more plans
   * used: in onko table and in the detail view therapy box
   * @param patientId
   * @returns
   */
  @action
  public fetchCurrentMedPlan = async (patientId: string): Promise<any> => {
    try {
      const today = moment(new Date()).format('YYYY-MM-DD');
      const res: any = await medicationTherapyClient.get<IMedicationItem[]>(
        `/medication-plan-administration?exactDate=${today}&patientId=${patientId}&order[startAt]=DESC&relations[]=warnings`
      );
      if (res.data[0]?.length > 0) {
        this.setCurrentMedplan(res.data[0][0]);
        this.setTherapyPlansList(res.data[0]);
      } else {
        this.setCurrentMedplan(null);
      }
      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchTherapyPlans
   * @description get (all) plans from a certian patient
   * @param param0
   * @returns
   */
  @action
  public fetchTherapyPlans = async ({
    patientId,
    skip = 0
  }: {
    patientId: string;
    skip: number;
  }): Promise<any> => {
    try {
      const take = 15;
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan-administration?patientId=${patientId}&take=${take}&skip=${skip} `
      );
      this.setTherapyPlansList(res.data[0]);
      this.medplans = [...this.medplans, ...res.data[0]];
      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchTherapyPlan
   * @description get a certain therapy plan
   */
  @action
  public fetchTherapyPlan = async (planId: string): Promise<any> => {
    if (isEmpty(planId)) return null;
    try {
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan-administration/${planId}`
      );
      this.setTherapyPlansList(res.data[0]);
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchArchivedMedPlans
   * @description retrieve the current ongoing therapy plan(s) also called medication plan
   * this can be one or more plans
   * used: in onko table and in the detail view therapy box
   */
  @action
  public fetchArchivedMedPlans = async (
    patientId: string,
    skip: number = 0
  ): Promise<any> => {
    try {
      const take = 10;
      let yesterday: any = moment(new Date()).format('YYYY-MM-DD');
      yesterday = moment(yesterday).subtract(1, 'days');
      yesterday = moment(yesterday).format('YYYY-MM-DD');
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan-administration?ltDate=${yesterday}&patientId=${patientId}&take=${take}&skip=${skip}order[endAt]=DESC`
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchMedication
   * @description medication for a certain therapy plan
   * this main informations is stored in the medication end api, but in
   * ohter end points (below)
   */
  @action
  public fetchMedication = async (planId: string): Promise<any> => {
    try {
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan-administration/${planId}/medication/overview`
      );
      this.setMedicationOverview(res.data[0]);
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchSideEffectsSettings
   * @description fetch side effects
   * @param patientId
   * @param medPlanId
   * @returns
   */
  @action
  public fetchSideEffectsSettings = async (
    patientId: string,
    medPlanId: string
  ): Promise<any> => {
    try {
      this.setSideEffectSettingsLoaded(false);
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${medPlanId}/side-effect-setting?patientId=${patientId}`
      );
      this.setSideEffectSettingsLoaded(true);
      this.setSideEffectSettings(res.data);
      return res.data;
    } catch (e) {
      return e;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchVitalDataSettings
   * @description post vital param en/disabled status
   * @param {string} medPlanId
   * @param {string} patientId
   * @returns
   */
  @action
  public fetchVitalDataSettings = async (
    patientId: string,
    medPlanId: string
  ): Promise<any> => {
    try {
      this.setVitalDataSettingsLoaded(false);
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${medPlanId}/vital-parameter-setting?patientId=${patientId}`
      );
      this.setVitalDataSettings(res.data);
      this.setVitalDataSettingsLoaded(true);
      return res.data;
    } catch (e) {
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchAlarmtTresholdReviewDaySideEffects
   * @description new api endpoint 12.09.2022 after refactoring
   * @param {string} medPlanId
   * @param {string} patientId
   * @returns
   */
  @action
  public fetchAlarmtTresholdReviewDaySideEffects = async (
    medPlanId: string,
    patientId: string
  ): Promise<any> => {
    try {
      this.sideEffectsSettingsLoaded = false;
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${medPlanId}/side-effect-observation/alarm-review?patientId=${patientId}`
      );

      this.alarmtTresholdReviewServerStatusSideEffects = {
        status: 200,
        message: ''
      };

      let format = res.data.map((item: any) => {
        return {
          ...item,
          title: item.name,
          values: item.values.length > 0 ? item?.values : ['-1'] // if no data set set type to empty color
        };
      });
      format = format?.sort((a: any, b: any) => b.values[0] - a.values[0]);

      const sortedData: any = format.sort((a: any, b: any) => {
        const dataA: any = new Date(a?.reviewDateAt);
        const dataB: any = new Date(b?.reviewDateAt);
        const res: any = dataB - dataA;
        return res;
      });

      this.setAlarmtTresholdReviewDaySideEffects(sortedData);

      return format;
    } catch (e) {
      const { response, message }: any = e;
      this.setAlarmtTresholdReviewDaySideEffects([]);
      this.alarmtTresholdReviewServerStatusSideEffects = {
        status: response?.status,
        message: `Daten konnten nicht geladen werden.`
      };
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchAlarmtTresholdReviewDayVitalData
   * @description new api endpoint 12.09.2022 after refactoring
   * @param {string} medPlanId
   * @param {string} patientId
   * @returns
   */
  @action
  public fetchAlarmtTresholdReviewDayVitalData = async (
    medPlanId: string,
    patientId: string
  ): Promise<any> => {
    try {
      this.sideEffectsSettingsLoaded = false;
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${medPlanId}/vital-parameter-observation/alarm-review?patientId=${patientId}`
      );

      this.alarmtTresholdReviewServerStatusVitalData = {
        status: 200,
        message: ''
      };

      this.setAlarmtTresholdReviewDayVitalData(res.data);
      return res;
    } catch (e) {
      const { response, message }: any = e;
      this.setAlarmtTresholdReviewDayVitalData([]);
      this.alarmtTresholdReviewServerStatusVitalData = {
        status: response?.status,
        message: message
      };
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/postSideEffectsObservationSettingStatus
   * @description post side enable/disbaled status
   * @param {string} patientId
   * @param {string} medPlanId
   * @param submitValues
   * @returns
   */
  @action
  public postSideEffectsObservationSettingStatus = async (
    data: ISetSettingStatus
  ): Promise<any> => {
    try {
      let { medicationPlanId } = data;
      const res: any = await medicationTherapyClient.post<ISetSettingStatus>(
        `/medication-plan/${medicationPlanId}/side-effect-setting`,
        data
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/postVitalDataObservationSettingStatus
   * @description post vital param en/disabled status
   * @param {string} medPlanId
   * @param {string} patientId
   * @param submitValues
   * @returns
   */
  @action
  public postVitalDataObservationSettingStatus = async (
    data: ISetSettingStatus
  ): Promise<any> => {
    try {
      let { medicationPlanId } = data;
      const res: any = await medicationTherapyClient.post<any>(
        `/medication-plan/${medicationPlanId}/vital-parameter-setting`,
        data
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/postSideEffectsThreshold
   * @description post threshold data
   * @param {string} medPlanId
   * @param submitValues
   * @param {string} patientId
   * @returns
   */
  @action
  public postSideEffectsThreshold = async (
    patientId: string,
    medicationPlanId: string,
    submitValues: { alarmThreshold: any[]; sideEffectId: string }
  ): Promise<any> => {
    if (isEmpty(submitValues)) return null;
    const { alarmThreshold, sideEffectId } = submitValues;
    const { max } = alarmThreshold[0];
    try {
      const res: any = await medicationTherapyClient.post<any>(
        `/medication-plan/${medicationPlanId}/side-effect-setting/set-alarm-threshold`,
        {
          color: 'RED',
          max: max,
          medicationPlanId: medicationPlanId,
          patientId: patientId,
          sideEffectId: sideEffectId
        }
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/postVitalThreshold
   * @description post vital parameter threshold data
   * @param {string} medPlanId
   * @param {string} patientId
   * @param submitValues
   * @returns
   */
  @action
  public postVitalThreshold = async (
    patientId: string,
    medPlanId: string,
    submitValues: {
      alarmThreshold: any[];
      vitalParameterId: string;
    }
  ): Promise<any> => {
    const { vitalParameterId, alarmThreshold } = submitValues;
    const { min, max } = alarmThreshold[0];
    try {
      const res: any = await medicationTherapyClient.post<any>(
        `/medication-plan/${medPlanId}/vital-parameter-setting/set-alarm-threshold`,
        {
          medicationPlanId: medPlanId,
          parameterId: vitalParameterId,
          patientId,
          min,
          max,
          color: 'RED'
        }
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchSideEffectObservations
   * @description get observation data for a certain side effect
   * @param {string} patientId
   * @param sideEffect
   * @param addedData
   * @returns
   */
  @action
  public fetchSideEffectObservations = async (
    patientId: string,
    sideEffect: { sideEffectId: string; medicationPlanId: string },
    addedData: { take: number; skip: number; gt: string | number } = {
      take: 50,
      skip: 0,
      gt: 0
    },
    viewType?: 'list' | 'graph',
    viewTypeGraph?: 'month' | 'day'
  ): Promise<any> => {
    const { sideEffectId, medicationPlanId } = sideEffect;
    if (
      isEmpty(patientId) ||
      isEmpty(medicationPlanId) ||
      isEmpty(sideEffectId)
    )
      return null;
    try {
      let { take, skip, gt } = addedData;
      take = take || 150;
      skip = skip || 0;
      const { startAt, endAt, id } = this.currentMedplan;

      gt = moment(new Date(startAt))
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      let lt: any = moment(endAt).format('YYYY-MM-DD');
      lt = moment(lt)
        .add(1, 'days')
        .format('YYYY-MM-DD');

      /**
       * @description graph view month: load observation gt: today + 1 and 1 month -1 one day
       **/
      if (viewType === 'graph') {
        lt = moment(new Date())
          .add(1, 'days')
          .format('YYYY-MM-DD');

        if (viewTypeGraph === 'day') {
          gt = moment(new Date())
            .subtract(1, 'months')
            .subtract(1, 'days')
            .format('YYYY-MM-DD');
        }

        if (viewTypeGraph === 'month') {
          gt = moment(new Date())
            .subtract(1, 'years')
            .format('YYYY-MM-DD');
        }
      }

      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${id}/side-effect-observation?patientId=${patientId}&sideEffectId=${sideEffectId}&take=${take}&skip=${skip}&ltDate=${lt}&gtDate=${gt}&relations[]=sideEffect&order[createdAt]=DESC`
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchVitalDataObservations
   * @description get observation data for a certain vital parameter
   * @param {string} patientId
   * @param vitalItem
   * @param addedData
   * @param {string} medPlanId
   * @returns
   */
  @action
  public fetchVitalDataObservations = async ({
    patientId,
    medPlanId,
    vitalItem,
    addedData,
    viewType,
    viewTypeGraph = 'day'
  }: {
    patientId: string;
    medPlanId: string;
    vitalItem: any;
    addedData: { take: number; skip: number; gt: string };
    viewType?: 'list' | 'graph';
    viewTypeGraph?: 'month' | 'day';
  }): Promise<any> => {
    const { vitalParameterId } = vitalItem;
    if (isEmpty(patientId) || isEmpty(medPlanId) || isEmpty(vitalParameterId))
      return null;
    try {
      let { take, skip, gt } = addedData;
      take = take || 50;
      skip = skip || 0;
      const { startAt, endAt, id } = this.currentMedplan;
      /**
       * @description default is list view load observation start enddate medplan
       **/
      gt = moment(new Date(startAt))
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      let lt: any = moment(endAt).format('YYYY-MM-DD');
      lt = moment(lt)
        .add(1, 'days')
        .format('YYYY-MM-DD');

      /**
       * @description graph view month: load observation gt: today + 1 and 1 month -1 one day
       **/
      if (viewType === 'graph') {
        lt = moment(new Date())
          .add(1, 'days')
          .format('YYYY-MM-DD');

        if (viewTypeGraph === 'day') {
          gt = moment(new Date())
            .subtract(1, 'months')
            .subtract(1, 'days')
            .format('YYYY-MM-DD');
        }

        if (viewTypeGraph === 'month') {
          gt = moment(new Date())
            .subtract(1, 'years')
            .format('YYYY-MM-DD');
        }
      }

      /**
       * @description graph view year: load observation gt: today + 1 and 1 month -1 one day
       **/

      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/${medPlanId}/vital-parameter-observation?patientId=${patientId}&vitalParameterId=${vitalParameterId}&take=${take}&skip=${skip}&ltDate=${lt}&gtDate=${gt}&relations[]=vitalParameter&order[validOnDateAt]=DESC`
      );
      return res;
    } catch (e) {
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchMedicationInTake
   * @description fetch info of adherence (medication-not-taken)
   * @param {string} patientId
   * @param take
   * @param skip
   * @returns
   */
  @action.bound
  public fetchMedicationInTake = async (
    patientId: string,
    take: number = 10,
    skip: number = 0
  ): Promise<any> => {
    try {
      skip = 0;
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-administration?patientId=${patientId}&take=${take}&skip=${skip}&order[exactDateTimeAt]=DESC&noErroneous=true`
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @method MedicationTherapyStore.api/fetchNotTakenMedication
   * @description fetch all none taken medication
   * @param patientId
   * @param take
   * @param skip
   * @returns
   */
  @action.bound
  public fetchNotTakenMedication = async (
    patientId: string,
    take: number = 30,
    skip: number = 0
  ): Promise<any> => {
    try {
      this.notTakenMedLoaded = false;
      const res: any = await medicationTherapyClient.get<any>(
        `/medication-plan/not-taken?patientId=${patientId}&take=${take}&skip=${skip}&order[exactDateTimeAt]=DESC`
      );
      this.notTakenMedLoaded = true;
      this.setNoneTakenMeds(res.data);
      const { data } = res;
      return !isEmpty(data) ? data : undefined;
    } catch (e) {
      return false;
    }
  };

  /**
   * @method MedicationTherapyStore.api/ackHcpWarning
   * @param {string} warningId
   * @returns
   */
  @action.bound
  public ackHcpWarning = async (warningId: string): Promise<any> => {
    try {
      const res: any = await medicationTherapyClient.patch<any>(
        `/hcp-warning/${warningId}`
      );
      return true;
    } catch (e) {
      return false;
    }
  };
}
