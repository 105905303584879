import moment from 'moment';
import { isEmpty } from 'lodash';
import { splitDataToCharts } from './global';
import { StringMap } from 'i18next';

const dateFormat = 'DD.MM.YYYY HH:mm';

/**
 * @memberof MedicationTherapyStore
 * @interface IListItem
 */
type IListItem = {
  x: string;
  y: string;
  y2: StringMap;
  yUnit: string;
  status: number;
  value: number;
  validOnDateAt: string;
  createdAt: string;
};

/**
 * @function  MedicationTherapyStore.helper/generateVitalDataListView
 * @param observationData 
 * @param settingItem 
 */
const generateVitalDataListView = async (
  observationData: any,
  settingItem: any
) => {
  /**
   * erstelle mapping für list view
   */
  const convertToMapping = observationData.map((item: any) => {
    // grenzwerte
    const val1 = item.values[0] && parseInt(item.values[0].value);
    const val2 = item.values[1] && parseInt(item.values[1].value);

    const { parameterDefinitions } =
      !isEmpty(item.vitalParameter) && item.vitalParameter;

    const parmDef = parameterDefinitions && parameterDefinitions[0];
    const unit = parmDef && ((parmDef.unit !== 'Smiley' && parmDef.unit) || '');

    /**
     * erstelle mapping für list view
     */
    const listData = observationData.map((itema: any) => {
      let value = !isNaN(val1) ? val1 : '-';
      if (
        item.values.length > 1 &&
        !isEmpty(item.values[0]) &&
        !isEmpty(item.values[1])
      ) {
        const parm1 = item.values[0].parameterId;
        const val1Id = parm1.charAt(parm1.length - 1);
        // display values according their parm id 1/2
        value = val1Id === 1 ? value + ' / ' + val2 : value + ' / ' + val2;
      }

      return {
        //createdAt: itema.createdAt,
        validOnDateAt: itema.validOnDateAt,
        value: value,
        yUnit: unit,
        status: vitalDataStatus(item, settingItem),
        y: val1,
        y2: val2
      };
    });
    /**
     * erstelle mapping für list view ende
     */

    const generateVitalDataSplitCharts: any = generateVitalSplitCharts(
      settingItem,
      observationData
    );

    return {
      listData: listData,
      status: 1,
      validOnDateAt: item.validOnDateAt,
      dataList: observationData,
      ...generateVitalDataSplitCharts //
      //lineSeries: [...ma],
      //markSeries: [{ x: '2022-09-13', y: 1 }],
      // min: 1,
      // max: 5,
      // step: 1,
      // isSideEffect: true,
      // yUnit: 1
    };
  });
};

/**
 * @function MedicationTherapyStore.helper/transformToVitalChartStructure
 * @description transform oberservationData to chart / list structure
 * @param {*} observationData
 */
const transformToVitalChartStructure = async (
  observationData: any,
  settingsItem: any
) => {
  if (isEmpty(observationData)) return null;
  const viewData: IListItem[] = observationData.map((item: any) => {
    const { createdAt, values, validOnDateAt } = item;
    const parsVal = (value: any) =>
      parseFloat(value.toString().replace(/,/g, '.'));

    // werte raussuchen
    const val1 = values[0] && parsVal(values[0].value);
    const val2 = values[1] && parsVal(values[1].value);
    let value = !isNaN(val1) ? val1 : '-';
    const { parameterDefinitions } =
      !isEmpty(item.vitalParameter) && item.vitalParameter;
    const parmDef = parameterDefinitions && parameterDefinitions[0];

    if (values.length > 1 && !isEmpty(values[0]) && !isEmpty(values[1])) {
      const parm1 = values[0].parameterId;
      const val1Id = parm1.charAt(parm1.length - 1);
      // display values according their parm id 1/2
      value = val1Id === 1 ? value + ' / ' + val2 : value + ' / ' + val2;
    }
    const unit = parmDef && ((parmDef.unit !== 'Smiley' && parmDef.unit) || '');

    return {
      x: moment(new Date(validOnDateAt)).toISOString(), //.format(dateFormat), /
      y: val1,
      y2: val2,
      yUnit: unit,
      status: 0, // vitalDataStatus(item, settingsItem),
      value, // if item has 2 values then both combined displayment in list/table view
      validOnDateAt,
      createdAt
    };
  });

  return viewData;
};

/**
 * @function MedicationTherapyStore.helper/generateVitalSplitCharts
 * @param vitalParameter 
 * @param viewData 
 * @returns 
 */
const generateVitalSplitCharts = (vitalParameter: any, viewData: any) => {
  // disable if (isEmpty(observationsData) || isEmpty(viewData)) return null;
  // disable const { vitalParameter } = observationsData[0];
  const { threshold } = !isEmpty(vitalParameter) && vitalParameter;
  // possible value for lineSerie and markSeries one
  const paramDef1 = !isEmpty(threshold) && threshold[0];
  // possible value for lineSerie and markSeries two
  const paramDef2 = !isEmpty(threshold) && threshold[1];
  const { lineSeries, markSeries }: any = splitDataToCharts(viewData);
  const hasSecondChartData = viewData.some((item: any) => item.y2);
  const { lineSeries2, markSeries2 } =
    hasSecondChartData && splitDataToCharts(viewData, true);

  const chartData = {
    lineSeries,
    markSeries,
    lineSeries2,
    markSeries2,
    paramDef1,
    paramDef2,
    step: 1,
    min: !isEmpty(paramDef1) && parseInt(paramDef1.min),
    max: !isEmpty(paramDef1) && parseInt(paramDef1.max),
    min2: !!paramDef2 && parseInt(paramDef2.min),
    max2: !!paramDef2 && parseInt(paramDef2.max),
    isSideEffect: false
  };
  return chartData;
};

/**
 * @function MedicationTherapyStore.helper/vitalDataStatus
 * @param observationItem 
 * @param settingsItem 
 * @returns 
 */
const vitalDataStatus = (observationItem: any, settingsItem: any) => {
  if (isEmpty(observationItem)) return null;
  let data = observationItem;
  data =
    data.length > 1
      ? data.reduce((a: any, b: any) =>
          a.values[0].value > b.values[0].value ? a : b
        )
      : data; // if data is an array find the item with the highest value

  /**
   * @description check all values
   */
  const { values } = data;

  const { alarmThreshold } = !isEmpty(settingsItem) && settingsItem;
  let defaultStatus = undefined;

  // if current value is in range, the item is green else is red
  values.map(
    (item: any) =>
      (defaultStatus = colorStatus(
        item,
        alarmThreshold,
        settingsItem.parameterType
      ))
  );

  return defaultStatus;
};

/**
 * @function MedicationTherapyStore.helper/colorStatus
 * @description check if a certain observation value is in range, if so, result is 0 (green), not red
 * and if no alarmSettings exists, then undefined (for not being checkable)
 * @param valueItem
 * @param alarmThreshold
 * @returns  0 | 1 | 3 | undefined
 */
const colorStatus = (
  valueItem: { parameterId: string; value: any },
  alarmThreshold: {
    parameterId: string;
    max?: any;
    min?: any;
    color: string;
  }[],
  type: string
): 0 | 1 | 3 | undefined => {
  if (type === 'howdy') {
    /**
     * temp fix need correct requirements
     */
    if (valueItem.value === 1) {
      return 3;
    }
    if (valueItem.value === 2) {
      return 3;
    }
    if (valueItem.value === 3) {
      return 1;
    }
    if (valueItem.value === 4) {
      return 0;
    }
    if (valueItem.value === 5) {
      return 0;
    }
    return valueItem.value;
  }
  if (isEmpty(valueItem) || valueItem === undefined || isEmpty(alarmThreshold))
    return undefined;
  let { parameterId: paramId, value: val } = valueItem;
  val = parseFloat(val);
  const alarmSetting: any = alarmThreshold.find(
    (item: any) => item.parameterId === paramId
  );
  if (alarmSetting === undefined) return undefined;
  let { min, max } = !isEmpty(alarmSetting) && alarmSetting;
  min = min !== undefined ? parseInt(min) : min;
  max = max !== undefined ? parseInt(max) : max;
  let isInRange: 0 | 1 | 3 | undefined;
  /**
   * @description calculate color theshold lamp compare values: min, max with threshold vitalData item
   */
  // option 0=green, 1=yellow, 3=red
  /*
  if (val > min && val < max) {
    isInRange = 0;
  } else if (val < min || val > max) {
    isInRange = 3;
  } else if (val === min || val === max) {
    isInRange = 1;
  }
  */
  if (val >= min && val <= max) {
    isInRange = 0;
  } else {
    isInRange = 3;
  }

  return isInRange; // 0 === green everything is good, red === is alarm
};

export {
  generateVitalDataListView,
  generateVitalSplitCharts,
  vitalDataStatus,
  transformToVitalChartStructure
};
