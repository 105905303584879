import React from 'react';
import { isEmpty } from 'lodash';
import findPatientItem from '../../services/findpatientItem';

/**
 * @function patientmanagement#templates/patientAddressTemplate
 * @description get patient adress form fields
   - address1
   - address2
   - city
   - zip
   - country
 * @param {Function} t get translation i18n
 * @param {Object[]} langCountries get language list of objects 
 * @param {any} patient get patient information and connect to form
 * @param {boolean} viewMode get boolean state to change viewmode to editmode form
 * @returns 
 */
const patientAddressTemplate = (
  t: Function,
  langCountries: [{ label: string; value: string }],
  patient: any,
  viewMode: boolean
) => {
  let patientObj;
  let address: any = null;
  let phone: any = {};

  if (!isEmpty(patient)) {
    address = patient.length > 0 && findPatientItem(patient, 'PatientAddress');
    phone =
      patient.length > 0 ? findPatientItem(patient, 'PatientPhone') : patient;

    let { address1, address2, city, zip, country } =
      !isEmpty(address) && address.content;

    patientObj = {
      homeAddress1: viewMode && isEmpty(address1) ? '-' : address1,
      homeAddress2: viewMode && isEmpty(address2) ? '-' : address2,
      homeCity: viewMode && isEmpty(city) ? '-' : city,
      homeZip: viewMode && isEmpty(zip) ? '-' : zip,
      homeCountry: isEmpty(country)
        ? !viewMode
          ? ''
          : '-'
        : langCountries?.[0]?.label
    };
  } else {
    address =
      !isEmpty(patient) &&
      patient.length > 0 &&
      findPatientItem(patient, 'PatientAddress');
    let { address1, address2, city, zip, country } =
      !isEmpty(address) && address.content;
    patientObj = {
      homeAddress1: viewMode && isEmpty(city) ? '-' : city,
      homeAddress2: viewMode && isEmpty(city) ? '-' : city,
      homeCity: viewMode && isEmpty(city) ? '-' : city,
      homeZip: viewMode && isEmpty(zip) ? '-' : zip,
      homeCountry: isEmpty(country)
        ? !viewMode
          ? ''
          : '-'
        : langCountries?.[0]?.label
    };
  }

  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    initialValues: patientObj,
    fields: [
      {
        key: 'label3',
        colSpan: 4,
        render() {
          return (
            <fieldset>
              <legend>{t('patient:homeAddress')}</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'homeAddress1',
        label: t('patient:streetNumber'),
        colSpan: 2,
        required: true,
        message: t('patient:requiredField')
      },
      {
        key: 'homeAddress2',
        label: t('patient:additionalData'),
        colSpan: 2,
        required: false,
        message: t('patient:requiredField')
      },
      {
        key: 'homeZip',
        label: t('patient:zip'),
        colSpan: 2,
        required: true,
        message: t('patient:requiredField')
      },
      {
        key: 'homeCity',
        label: t('patient:city'),
        colSpan: 2,
        required: true,
        message: t('patient:requiredField')
      },
      {
        key: 'homeCountry',
        options: langCountries,
        widget: 'select',
        label: t('patient:country'),
        colSpan: 2,
        required: true,
        message: t('patient:requiredField')
        // initialValue: 'DE',
      }
    ]
  };

  return meta;
};

export default patientAddressTemplate;
