import React, { FC } from 'react';
import { Badge, Tooltip } from 'antd';
import CasesTooltipSideffectsListThresholdLamp from '../CasesTooltipSideffectsListThresholdLamp/CasesTooltipSideffectsListThresholdLamp';
import { ICasesSideEffectsThresholdTooltip } from '../../models/components/ICasesSideEffectsThresholdTooltip';
import calculatelLampFromAllSideEffects from '../../config/calculatelLampFromAllSideEffects';

/**
 * ---
 * CasesSideEffectsThresholdTooltip
 * ---
 * CasesOverview need in column a bubble with highest value from a sideeeffect list with
 * tooltip list of last entry day a highest value from sideeffects
 * @class patientmanagement.components/CasesSideEffectsThresholdTooltip
 * @type {React.FC<ICasesSideEffectsThresholdTooltip>}
 * @return {React.ReactElement}
 */
const CasesSideEffectsThresholdTooltip: FC<ICasesSideEffectsThresholdTooltip> = ({
  alarmThresholdReview
}): JSX.Element => {
  if (alarmThresholdReview === undefined) {
    return <></>;
  }
  /**
   * @description get traffic light config
   */
  const bg: any = calculatelLampFromAllSideEffects(alarmThresholdReview);

  /**
   * @description change color in different alaram threshold
   */
  const colorText = bg === '#f8607a' ? '#fff' : '#333';

  /**
   * Tooltip list
   */
  const ToolTipCase = CasesTooltipSideffectsListThresholdLamp(
    alarmThresholdReview
  );

  return (
    <Tooltip
      overlayClassName="tool-tip-item side-effect"
      placement="bottomLeft"
      color="white"
      title={ToolTipCase}
    >
      <div
        style={{
          width: 40,
          height: 40,
          margin: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Badge
          count={
            alarmThresholdReview !== undefined
              ? alarmThresholdReview?.length
              : ''
          }
          //className={highestValue === undefined ? 'badge none' : ''}
          style={{
            border: 'solid 1px rgba(49,50,55,.5)',
            cursor: 'pointer',
            color: colorText, //amountFontColor
            backgroundColor: bg || 'transparent',
            lineHeight: '1.5'
          }}
          title=" " // this is needed to avoid double tooltip
        />
      </div>
    </Tooltip>
  );
};

export default CasesSideEffectsThresholdTooltip;
