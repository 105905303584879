import React, { FC } from 'react';
import { ICaseDetailHeader } from '../../models/components/ICaseDetailHeader';
import CaseTreatment from '../CaseTreatment/CaseTreatment';
import CaseCardPatient from '../CaseCardPatient/CaseCardPatient';

/**
 * ---
 * CaseDetailHeader
 * ---
 * wrapper format layout header two columns only desktop view,
 * - Card Case Patient information
 * - Card Case Treaatment information
 * @class patientmanagement.components/CaseDetailHeader
 * @type {React.FC<ICaseDetailHeader>}
 * @return {React.ReactElement}
 */
const CaseDetailHeader: FC<ICaseDetailHeader> = ({
  patientCareStore,
  showSkeleton
}: ICaseDetailHeader): JSX.Element => {
  return (
    <div className={'dtb-detail-header'}>
      <div
        className={'dtb-detail-header-split-item'}
        style={{ margin: 0, padding: 0 }}
      >
        <CaseCardPatient
          patientCareStore={patientCareStore}
          showSkeleton={showSkeleton}
          size={'default'}
        />
      </div>
      <div className={'dtb-detail-header-split-item'}>
        <CaseTreatment
          patientCareStore={patientCareStore}
          showSkeleton={showSkeleton}
          size={'default'}
        />
      </div>
    </div>
  );
};

export default CaseDetailHeader;
