export default {
  firstName: 'Vorname',
  lastName: 'Nachname',
  gender: 'Geschlecht',
  male: 'männlich',
  female: 'weiblich',
  diverse: 'divers',
  birthday: 'Geburtstag',
  selectDate: 'Datum wählen',
  decline_to_specify: 'Keine Angabe',

  size: 'Größe',
  weight: 'Gewicht',

  phone: 'Telefon',
  mobilePhone: 'Mobiltelefon',
  email: 'E-Mail',

  homeAddress: 'Wohnanschrift',
  streetNumber: 'Straße, Hausnr.',
  zip: 'PLZ',
  zipCity: 'PLZ, Ort',
  city: 'Ort',
  contact: 'Kontakt',
  country: 'Land',

  name: 'Name',
  insurance: 'Versicherung',
  insuranceNumber: 'Versicherungsnr.',
  insuranceType: 'Versicherungsart',
  private: 'privat',
  public: 'gesetzlich',

  zuzahlungsfrei: 'zuzahlungsfrei',
  zuzahlungspflichtig: 'zuzahlungspflichtig',

  patient: 'Patient',
  info: 'Patienteninformation',
  informationRequired: 'Bitte ausfüllen',
  noAdditionalData: 'keine Daten vorhanden',
  additionalData: 'Zusatz',

  bodyHeight: 'Körpergröße',
  bodyWeight: 'Körpergewicht',
  bodySurface: 'Körperoberfläche',
  requiredField: 'Pflichtfeld',

  save: 'Speichern',
  cancel: 'Abbrechen',
  edit: 'Bearbeiten',
  treatment: 'Behandlung',
  treatmentId: 'Behandlungs-ID',
  description: 'Beschreibung'
};
