const config = {
  keycloak: {
    clientId: "hub-connect",
    realm: "comjoo-hub",
    url: "https://sso.tino-dtb.net/auth",
  },
  io: {
    url: "https://medipolis.socket.api.tino-dtb.net",
    path: "/socket.io",
  },
  jitsimeet: {
    domain: "meet.tino-dtb.net",
    libraryName: "external_api.min.js",
    config: {},
  },
  patientManagement: {
    url: "https://pm-doc.tino-dtb.net",
  },
  medicationTherapyManagement: {
    url: "https://medication-doc.tino-dtb.net",
  },
  therapyDiagnoses: {
    url: "https://therapy-api.tino-dtb.net",
  },
  dtbplaner: {
    url: "https://therapieplaner.tino-dtb.net",
  },
  process: {
    url: "https://process-api.tino-dtb.net",
  },
  pusher: {
    channels: {
      appId: "966073",
      key: "1f670dfc44fb6ca41558",
      cluster: "eu",
    },
  },
  meet: {
    url: "https://meet-development.tino-dtb.net",
  },
};

export default config;
