import defaultConfig from './default';
import developmentConfig from './development';
import stagingConfig from './staging';
import productionConfig from './production';

let config: any = defaultConfig;

if (window.location.hostname === process.env.REACT_APP_DEVELOPMENT_URL)
  config = developmentConfig;
if (window.location.hostname === process.env.REACT_APP_STAGING_URL)
  config = stagingConfig;
if (window.location.hostname === process.env.REACT_APP_PRODUCTION_URL)
  config = productionConfig;

export default config;
