import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from 'antd';
import CasesArchiveTable from '../../components/Tables/CasesArchiveTable/CasesArchiveTable';
import CasesHeader from '../../components/CasesHeader/CasesHeader';
import { ICasesArchiveOverview } from '../../models/pages/ICasesArchiveOverview';
import './../../scss/pages/CasesOverview.scss';

const { Content } = Layout;

/**
 * ---
 * title: CasesArchivOverview
 * ---
 *
 * #### inject stores
 * - patientCareStore
 * - medicationTherapyStore
 * - authStore
 * - organisationManagementStore
 *
 * #### include Components
 * - CasesHeader
 * - CasesArchiveTable
 *
 * #### init loading
 * - load cases with subdata is needed
 *
 * @class patientmanagement#pages/CasesArchivOverview
 * @property @link ICasesArchivOverview>
 * @return {React.ReactElement}
 * @returns
 */
const CasesArchivOverview: FC<ICasesArchiveOverview> = ({
  patientCareStore,
  medicationTherapyStore,
  authStore,
  organisationManagementStore,
  navigateToLink
}): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const orgConfig = organisationManagementStore.getOrgConfig;
  const userAttributes = authStore.getUserAttributes;
  const { fetchArchivedCases } = patientCareStore;
  const casesArchiveLoaded: boolean = patientCareStore.getCasesArchiveLoaded;

  useEffect(() => {
    !casesArchiveLoaded && getCases();
    return () => {
      patientCareStore.setCasesArchiveLoaded(false);
      patientCareStore.setCasesArchive([]);
    };
  }, [patientCareStore, medicationTherapyStore]);

  const getCases = async () => {
    setLoaded(!loaded);
    await fetchArchivedCases();
  };

  /**
   * @description search value
   * @param searchFilter
   */
  const search = (searchFilter: any): void => {
    setSearchFilter(searchFilter);
    fetchArchivedCases(searchFilter);
  };

  /**
   * @description
   * @param e
   */
  const onchangeFilter = (e: any): void => {
    setSearchFilter(e.target.value);
  };

  /**
   * @description  change filter
   * @param filter
   */
  const changeFilterTreatmentState = (filter: string): void => {
    patientCareStore.setFilterTreatmentState(filter);
    setSearchFilter('');
    fetchArchivedCases('');
  };

  const chosenFilterOption = patientCareStore.getCasesArchiveFilter;

  return (
    <Layout>
      <Content
        className=" about-wrapper "
        style={{
          padding: '0px 0px',
          margin: 0,
          marginBottom: '14px',
          flexDirection: 'column'
        }}
      >
        <CasesHeader
          search={search}
          onchangeFilter={onchangeFilter}
          searchFilter={searchFilter}
          invitePatient={() => {}}
          userAttributes={userAttributes}
          orgConfig={orgConfig}
          isArchiv={true}
          chosenFilterOption={chosenFilterOption}
          changeFilterTreatmentState={changeFilterTreatmentState}
          casesLoaded={casesArchiveLoaded}
        />
        <CasesArchiveTable
          patientCareStore={patientCareStore}
          medicationTherapyStore={medicationTherapyStore}
          navigateToLink={navigateToLink}
        />
      </Content>
    </Layout>
  );
};

export default inject(
  'patientCareStore',
  'medicationTherapyStore',
  'authStore',
  'organisationManagementStore'
)(observer(CasesArchivOverview));
