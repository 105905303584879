export default {
  ongoing: 'Ongoing',
  planned: 'Planned',
  started: 'Started',
  completed: 'Completed',
  done: 'Completed',
  completed_report_started: 'Started',
  result_available: 'Result available',
  aborted_done: 'Aborted Done',
  on_hold: 'Waiting',
  not_assigned: 'Not assigned',
  aborted: 'Aborted',
  noData: 'No therapy information available'
};
