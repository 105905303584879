export default {
  name: 'Name',
  cycle: 'Cycle',
  days: 'Days',
  status: 'Status',
  nrDaysOfCycle: 'Duration',
  startedAt: 'Startdate',
  endAt: 'Enddate',
  state: 'Status',
  attendingPhysicianName: 'Attending physician',
  loadMore: 'Load more',
  noData: 'No therapy information available'
};
