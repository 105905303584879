import { action, computed, makeObservable, observable } from "mobx";
import { patientManagementClient } from "../../axios";
import pusher from "../../pusher";
import { HcpStore } from "../HcpStore/HcpStore";
import { PatientCareStore } from "../PatientCareStore/PatientCareStore";
import IMessage from "./models/IMessage";

/**
 * MobX MessagingStore
 * @property {any} pushInstance - private pusher instace
 * @property {any} fileList - private observable
 * @property {number | any} unreadMessages - private observable
 * @property {any[]} messages - private observable
 * @property {string} searchValue - private observable
 * @property {any} hcpStore - private hcpStore instance
 */
export class MessagingStore {
  @observable
  private pushInstance: any = null;
  @observable fileList: any = [];
  @observable
  private unreadMessages: number | any = 0;
  @observable
  private messages: any[] = [];
  @observable
  private searchValue: string = "";
  // dependencies stores
  private hcpStore;

  constructor() {
    makeObservable(this);
    // dependencies store
    this.hcpStore = new HcpStore();
    this.pushInstance = pusher;
  }

  /**
   * @method MessagingStore.getMessages
   * @description get messages
   */
  @computed
  get getMessages() {
    let tempMessages: any = this.messages.filter((messageEntry: any) =>
      this.checkMessageFilter(messageEntry, this.searchValue)
    );

    return this.messages;
  }

  /**
   * @method MessagingStore.checkMessageFilter
   * @description Return whether the provided massage matches the provided searchValue.
   * @param messageEntry
   * @param searchValue
   * @returns {*|boolean}
   */
  private checkMessageFilter(messageEntry: any, searchValue: string) {
    const cleanedSearchValue = searchValue.toLowerCase();
    const { content } = messageEntry;
    return content.toLowerCase().includes(cleanedSearchValue);
  }

  /**
   * @method MessagingStore.getUnreadMessages
   * @description get unreadMessages
   */
  @computed
  get getUnreadMessages(): IMessage[] {
    return this.unreadMessages;
  }

  /**
   * @method MessagingStore.getFileList
   * @description get fileList
   */
  @computed
  get getFileList(): any {
    return this.fileList;
  }

  /**
   * @method MessagingStore.getSearchValue
   * @description get search value
   */
  @computed
  get getSearchValue(): string {
    return this.searchValue;
  }

  /**
   * @method MessagingStore.fetchUnreadMessageCount
   * @param {string} messageProcessId
   */
  @action
  public fetchUnreadMessageCount = async (
    messageProcessId: string
  ): Promise<any> => {
    try {
      const res: any = await patientManagementClient.get<any>(
        `/process/${messageProcessId}`
      );
      this.unreadMessages = res.data.orgUnreadMessages;
    } catch (e) {
      return e;
    }
  };

  /**
   * @method MessagingStore.clearUnreadMesssagesCount
   * @param {string} messageProcessId
   */
  @action
  public clearUnreadMesssagesCount = async (
    messageProcessId: string
  ): Promise<any> => {
    this.unreadMessages = 0;
    try {
      const res: any = await patientManagementClient.post<any>(
        `/process/${messageProcessId}/set-unread`
      );
    } catch (e) {}
  };

  /**
   * @method MessagingStore.resetUnreadMessages
   */
  @action.bound
  public resetUnreadMessages = (): void => {
    this.unreadMessages = 0;
  };

  /**
   * @method MessagingStore.addAttachment
   * @description add attachment to fileList
   * @param {*} file
   */
  @action.bound
  addAttachment(file: any): void {
    this.fileList.push(file);
  }

  /**
   * @method MessagingStore.removeAttachment
   * @description remove attachment to fileList
   * @param {*} file
   */
  @action.bound
  removeAttachment(obj: any): void {
    this.fileList.remove(obj);
  }

  /**
   * @method MessagingStore.postAttachments
   * @description post attachments
   * @param {string} messageProcessId
   * @param {*} file
   */
  @action.bound async postAttachments(
    messageProcessId: string,
    file: any
  ): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("content", ""); // file.name
    try {
      const res: any = await patientManagementClient.post<any>(
        `/process/${messageProcessId}/content`,
        formData
      );
      this.resetFileList();
      this.resetMessages();
      await this.fetchMessages(messageProcessId, 20, 0, {}, "", {});

      return res.data !== undefined ? true : false;
    } catch (error) {
      return false;
    }
  }

  /**
   * @method MessagingStore.downloadAttachment
   * @description downlload attachments
   * @param {string} messageProcessId
   * @param {string} id
   * @param {string} name
   */
  @action.bound async downloadAttachment(
    messageProcessId: string,
    id: string,
    name: string
  ): Promise<any> {
    try {
      const response: any = await patientManagementClient.get<any>(
        `/process/${messageProcessId}/content/attachments/${id}`,
        { responseType: "blob" }
      );

      const fileTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
      ];
      const data = response.data;
      const type = data.type;
      const file: any = new Blob([data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(file);
      if (fileTypes.includes(type)) {
        const win: any = window.open(url, "_blank");
        win.focus();
      } else {
        const link: any = Object.assign(document.createElement("a"), {
          href: url,
          download: name,
        });
        document.body.appendChild(link);
        link.click();
      }
      window.URL.revokeObjectURL(file);

      return "success";
    } catch (error) {
      return "error";
    }
  }

  /**
   * @method MessagingStore.fetchMessages
   * @description Fetches all messages and updates the caseList
   * @param {string} messageProcessId
   * @param {number} take
   * @param {number} skip
   * @param {any} authUser
   * @param {string} patientId
   * @param {any} patient
   */
  @action
  public fetchMessages = async (
    messageProcessId: string,
    take: number,
    skip: number,
    authUser: any,
    patientId: string,
    patient: any
  ): Promise<any> => {
    try {
      const res: any = await patientManagementClient.get<IMessage[]>(
        `/process/${messageProcessId}/content?take=${take}&skip=${skip}`
      );

      // get hcps
      /**
       * FIX ME reafctoring do not use promise all
       */
      var mapMessagesWithHcps = await Promise.all(
        res.data.map(async (message: any) => {
          let authUserTemp = { firstName: "Unknown", lastName: "Unknown" };
          if (
            message.authorId !== authUser.id &&
            message.authorId !== patient.id
          ) {
            authUserTemp = await this.hcpStore.getHcp(message.authorId);
          }
          if (message.authorId === patient.id) {
            authUserTemp = {
              firstName: patient.firstName,
              lastName: patient.lastName,
            };
          }

          if (message.authorId === authUser.id) {
            authUserTemp = {
              firstName: authUser.firstName,
              lastName: authUser.lastName,
            };
          }

          return {
            ...message,
            authorName: authUserTemp,
            //id: message.id,
            //content: message.content,
            //attachment: message.contentObject,
            //author: message.authorId,
            //type: 'incoming', // message.authorId === authUser.id ? 'outgoing' : 'incoming',
            //createdAt: message.createdAt,
            patient: message.authorId === authUser.id ? false : true,
            type: message.authorId === authUser.username ? "start" : "end",
          };
        })
      );

      this.messages = [...mapMessagesWithHcps.reverse(), ...this.messages];
      /*
      
        const fooo = await this.mapMessage(
          message,
          authUser,
          patientId,
          patient
        );

        resolveMessages.push({
          //  ...message,
          authorName: authUser.firstName + ' ' + authUser.lastName,
          id: message.id,
          content: message.content,
          attachment: message.contentObject,
          author: message.authorId,
          //type: 'incoming', // message.authorId === authUser.id ? 'outgoing' : 'incoming',
          createdAt: message.createdAt
        });
      });
      */

      // convert message obj promise
      /*
      await mapMessagesWithHcps.map(async (it: any) => {
        await it.then((item: any) => {
          resolveMessages.push(item);
        });
      });
      */
      /*
      setTimeout(() => {
        this.messages = [...res.data.reverse(), ...this.messages];
      }, 2500);
      */
    } catch (e) {}
  };

  /**
   * @method MessagingStore.fetchMessagesSearch
   * @param {string} messageProcessId
   * @param {number} take
   * @param {number} skip
   * @param {any} authUser
   * @param {string} patientId
   * @param {any} patient
   */
  @action
  public fetchMessagesSearch = async (
    messageProcessId: string,
    take: number,
    skip: number,
    authUser: any,
    patientId: string,
    patient: any
  ): Promise<any> => {
    try {
      const res: any = await patientManagementClient.get<IMessage[]>(
        `/process/${messageProcessId}/content?take=${take}&skip=${skip}&search=${this.searchValue}`
      );

      // get hcps
      /**
       * FIX ME reafctoring do not use promise all
       */
      var mapMessagesWithHcps = await Promise.all(
        res.data.map(async (message: any) => {
          let authUserTemp = { firstName: "Unknown", lastName: "Unknown" };
          if (
            message.authorId !== authUser.id &&
            message.authorId !== patient.id
          ) {
            authUserTemp = await this.hcpStore.getHcp(message.authorId);
          }
          if (message.authorId === patient.id) {
            authUserTemp = {
              firstName: patient.firstName,
              lastName: patient.lastName,
            };
          }

          if (message.authorId === authUser.id) {
            authUserTemp = {
              firstName: authUser.firstName,
              lastName: authUser.lastName,
            };
          }

          return {
            ...message,
            authorName: authUserTemp,
            id: message.id,
            content: message.content,
            attachment: message.contentObject,
            author: message.authorId,
            type: "incoming", // message.authorId === authUser.id ? 'outgoing' : 'incoming',
            createdAt: message.createdAt,
          };
        })
      );
      this.messages = [...mapMessagesWithHcps.reverse()];
    } catch (e) {}
  };

  /**
   * @method MessagingStore.mapMessage
   * @description Maps the given message entry to a message object matching the ui's need
   * @param messages
   * @returns {object}
   */
  @action
  private mapMessage = async (
    message: any,
    authUser: any,
    patientId: string,
    patient: any
  ) => {
    if (message.authorId === authUser.id || message.authorId === patientId) {
      return {
        ...message,
        authorName:
          message.authorId === authUser.id
            ? authUser.firstName + " " + authUser.lastName
            : patient.firstName + " " + patient.lastName,
      };
    } else {
      const user = await this.hcpStore.getHcp(message.authorId);
      return {
        ...message,
        authorName: user.firstName + " " + user.lastName,
        id: message.id,
        content: message.content,
        attachment: message.contentObject,
        author: message.authorId,
        //type: 'incoming', // message.authorId === authUser.id ? 'outgoing' : 'incoming',
        createdAt: message.createdAt,
      };
    }
  };

  /**
   * @method MessagingStore.postMessage
   * @description post message
   * @param {string} messageProcessId
   * @param {string} message
   */
  @action
  public postMessage = async (
    messageProcessId: string,
    message: string
  ): Promise<any> => {
    try {
      const res: any = await patientManagementClient.post<any>(
        `/process/${messageProcessId}/content`,
        { content: message }
      );
      this.resetMessages();
      await this.fetchMessages(messageProcessId, 20, 0, {}, "", {});

      return res;
    } catch (e) {
      return e;
    }
  };

  /**
   * @method MessagingStore.resetMessages
   * @description reset messages
   */
  public resetMessages = (): void => {
    this.messages = [];
  };

  /**
   * @method MessagingStore.resetFileList
   * @description clean fileList
   */
  @action.bound resetFileList(): void {
    this.fileList = [];
  }

  /**
   * @method MessagingStore.setSearchValue
   * @description Set the value by which the case should by filtered by title or patient
   * @param {string} value
   */
  @action
  public setSearchValue = (value: string): void => {
    this.searchValue = value;
  };

  /**
   * @method MessagingStore.resetSearchValue
   * @description Reset the searchValue
   */
  @action
  public resetSearchValue = (): void => {
    this.searchValue = "";
  };
}
