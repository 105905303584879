export default {
  checkingPermissionStatus: 'Prüfe Berechtigungsstatus…',
  forMedicalInstitutions: 'FÜR MEDIZINISCHE EINRICHTUNGEN…',
  noPermissionWarning: 'Diese Applikation ist nur medizinisches Personal gestattet.',
  goodMorning: 'Guten Morgen',
  goodEvening: 'Guten Abend',
  hello: 'Guten Tag',
  header: { subtitle: 'für medizinische Einrichtungen' },
  noNotifications: 'Keine neuen Benachrichtigungen'
};
