export default {
  name: 'Name',
  cycle: 'Zyklus',
  days: 'Tage',
  status: 'Status',
  nrDaysOfCycle: 'Dauer',
  startedAt: 'Startdatum',
  endAt: 'Enddatum',
  state: 'Status',
  attendingPhysicianName: 'Behandelnder Arzt',
  loadMore: 'Mehr laden',
  noData: 'Keine Therapieinformationen verfügbar'
};
