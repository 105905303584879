import React from 'react';
import moment from 'moment';
import { Row } from 'antd';
import { isEmpty } from 'lodash';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
//import '../../../../stylesheets/CasesOverview/CasesList.scss';

export const mapDescriptionRow = (cases: any, doAckHcpWarning: any, t: any) =>
  cases.map((item: any) => {
    // expand cases with warnings banner (list)
    // each case with warning should have this infos and each warning should
    // have it one row with the ui defined below
    const { currentTherapyPlan } = item;
    if (
      !currentTherapyPlan ||
      !currentTherapyPlan.warnings ||
      currentTherapyPlan.warnings.length === 0
    ) {
      return item;
    }
    const warningRows = currentTherapyPlan.warnings
      .sort((a: any, b: any) => new Date(a.createdAt) < new Date(b.createdAt))
      .filter(
        (warning: any) =>
          !!warning.reason ||
          (!isEmpty(warning.reason) &&
            warning.reason.reasonState &&
            warning.reason.reasonState !== 'ENTERED-IN-ERROR')
      )
      .map((warning: any) => {
        const { warningId, type, reason, createdAt } = warning;
        let {
          reasonObjectName,
          reasonState,
          reasonStateComment,
          affectedValues /* reasonViolatedRules */
        } = !isEmpty(reason) && reason;
        const explanation = [
          moment(createdAt).format('DD.MM.YYYY'),
          t(`PatientCare.warningBanner.${type}`)
        ];
        reasonObjectName && explanation.push(reasonObjectName);
        reasonState &&
          explanation.push(t(`PatientCare.warningBanner.${reasonState}`));
        reasonStateComment &&
          explanation.push(
            t('PatientCare.general.reason') + ': ' + reasonStateComment
          );
        affectedValues && explanation.push(affectedValues);
        // this is only for testing: reasonViolatedRules && explanation.push('( ' + reasonViolatedRules + ' )');

        return (
          <Row key={warningId} className="banner-error">
            <WarningOutlined className="icon-item" />
            {explanation.join(' ')}
            <CloseOutlined
              className="right icon-item"
              onClick={() => doAckHcpWarning(warningId)}
            />
          </Row>
        );
      });
    return { ...item, warning: warningRows };
  });

export const expandedRowKeys = (cases: any) =>
  /* function needs to be extended to check if a cases/row has warning infos,
    if so, return case-id so it will be add to the list of expanded row keys.
    This is used, to expand case-rows with warning banners on default.
 */
  cases.map((item: any) => {
    return item.id;
  });
