import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const supportedLanguages = ['de']; // , 'en'

i18next
  // lazy loading load translation
  .use(Backend)
  // .use(XHR)
  // detect user language
  // temp disable .use(LanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: 'de',
    supportedLngs: supportedLanguages,
    debug: false, // debug testing
    backend: [
      LocalStorageBackend, // primary
      XHR // fallback
    ],
    interpolation: {
      useRawValueToEscape: false,
      escapeValue: false // react already safes from xss
    },
    react: {
      bindI18n: 'languageChanged'
    }
  });

export default i18next;
